import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "navigation/PrivateRoute";

import { routeConstants } from "navigation";
import MainPage from "pages/MainPage/MainPage";
import FavouritePage from "pages/FavouritePage/FavouritePage";
import LoginPage from "pages/auth/LoginPage/LoginPage";
import RegisterPage from "pages/auth/RegisterPage/RegisterPage";
import ForgetPasswordPage from "pages/auth/ForgetPasswordPage/ForgetPasswordPage";
import ProductDetails from "pages/ProductDetailsPage/ProductDetailsPage";
import CheckoutPage from "pages/CheckoutPage/CheckoutPage";
import AccountPage from "pages/AccountPage/AccountPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import OrdersPage from "pages/OrdersPage/OrdersPage";
import OrderDetailsPage from "pages/OrderDetailsPage/OrderDetailsPage";
import OrderRoutes from "pages/OrderRoutes/OrderRoutes";
import OrdersListPage from "pages/OrdersListPage/OrdersListPage";
import FilterPage from "pages/FilterPage/FilterPage";
import AboutPage from "pages/AboutPage/AboutPage";
import TermsConditionsPage from "pages/TermsConditionsPage/TermsConditionsPage";
import ContactPage from "pages/ContactPage/ContactPage";

export const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path={routeConstants.root.route} element={<MainPage />} />

			<Route
				path={routeConstants.favourite.route}
				element={<FavouritePage />}
			/>
			<Route
				path={`${routeConstants.productDetails.route}/:id`}
				element={<ProductDetails />}
			/>
			<Route
				path={`${routeConstants.checkout.route}`}
				element={<CheckoutPage />}
			/>

			<Route
				path={`${routeConstants.filter.route}/:category`}
				element={<FilterPage />}
			/>

			<Route path={routeConstants.about.route} element={<AboutPage />} />
			<Route
				path={routeConstants.termsConditions.route}
				element={<TermsConditionsPage />}
			/>
			<Route path={routeConstants.contact.route} element={<ContactPage />} />

			<Route path={routeConstants.account.route} element={<AccountPage />}>
				<Route index element={<ProfilePage />} />
				<Route path="profile" element={<ProfilePage />} />

				{/* Nested order routes */}
				<Route path="orders" element={<OrdersPage />}>
					<Route index element={<OrdersListPage />} />
					<Route path=":id" element={<OrderDetailsPage />} />
				</Route>
			</Route>

			<Route path={routeConstants.login.route} element={<LoginPage />} />
			<Route path={routeConstants.register.route} element={<RegisterPage />} />
			<Route
				path={routeConstants.forgetPassword.route}
				element={<ForgetPasswordPage />}
			/>

			{/*<Route path="*" element={<UserRoutes />} />*/}
			{/* <Route path="*" element={<PrivateRoute component={UserRoutes} />} />
			<Route element={<NotFoundPage />} /> */}
		</Routes>
	);
};
