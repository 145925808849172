import { Button } from "common/Button/Button";
import OrderStatus from "common/OrderStatus/OrderStatus";
import { P } from "common/P/P";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import { orderService } from "services/orderService";

interface MobileOrdersProps {
	orders: any;
	onRowClick: (order: any) => void;
}

const MobileOrders: React.FC<MobileOrdersProps> = ({ orders, onRowClick }) => {
	const { t } = useTranslation();
	const handleCancelOrder = async (
		event: React.MouseEvent<HTMLButtonElement>,
		order: any
	) => {
		event.stopPropagation();
		if (order.orderStatus === "Waiting") {
			try {
				const updatedOrder = await orderService.updateUserOrder(
					order.userId,
					order._id,
					{ orderStatus: "Cancelled" }
				);
				window.location.reload();
				// Update the state or re-fetch orders to reflect the changes
			} catch (error) {
				console.error("Error cancelling order:", error);
			}
		}
	};
	return orders.map((order: any, idx: any) => (
		<div className="p-4 bg-white" onClick={() => onRowClick(order)}>
			<div className="grid w-full grid-flow-row grid-cols-1 gap-3 border-b border-dividerColor auto-cols-max">
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p3" type="regular" className="text-grayTwo">
						{t("another.orderId")}
					</P>
					<P size="p3" type="light">
						{order._id}
					</P>
				</div>
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p3" type="regular" className="text-grayTwo">
						{t("another.date")}
					</P>
					<P size="p3" type="light">
						{new Date(order.orderDate)
							.toLocaleDateString("en-GB", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
							})
							.replace(/\//g, ".")}
					</P>
				</div>
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p3" type="regular" className="text-grayTwo">
						{t("another.totalPrice")}
					</P>
					<P size="p3" type="light">
						{order.orderSummary.total} {serviceConfig.currencySign}
					</P>
				</div>
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p3" type="regular" className="text-grayTwo">
						{t("another.status")}
					</P>
					<P size="p3" type="light">
						<OrderStatus status={order.orderStatus} />;
					</P>
				</div>
			</div>
			<div className="grid pt-3 grid-cols-maxTwo place-content-between place-items-center">
				<P size="p3" type="regular" className="text-grayTwo">
					{t("another.cancelOrder")}
				</P>
				<Button
					appearance={order.orderStatus === "Waiting" ? "black" : "white"}
					className={
						order.orderStatus === "Waiting"
							? "cursor-pointer hover:bg-grayOne text-xs"
							: "cursor-not-allowed text-xs"
					}
					paddingX="px-6"
					paddingY="py-2"
					grid="grid"
					onClick={(event) => handleCancelOrder(event, order)}
					disabled={order.orderStatus !== "Waiting"}
				>
					{t("another.cancel")}
				</Button>
			</div>
		</div>
	));
};

export default MobileOrders;
