// src/pages/AccountPage.tsx
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { ReactComponent as NoAccountIcon } from "common/assets/empty_icons/no_account.svg"; // Replace with the path to your SVG
import { Button } from "common/Button/Button";
import { routeConstants } from "navigation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "components/App/store";
import Modal from "common/Modal/Modal";
import { logout } from "redux/auth/authSlice";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const AccountPage: React.FC = () => {
	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const user = useSelector((state: RootState) => state.auth.user);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const userLoading = useSelector((state: RootState) => state.auth.loading);

	const getTitleFromPath = (path: string): string => {
		const sections = path.split("/");
		const lastSection = sections[sections.length - 1];

		if (sections.includes("orders") && !isNaN(Number(lastSection))) {
			return "Order Details";
		}

		if (lastSection === "account" || lastSection === "profile") {
			return "Profile";
		}

		switch (lastSection) {
			case "orders":
				return "Orders";
			default:
				return "";
		}
	};

	const isActiveTab = (path: string): boolean => {
		if (location.pathname === "/account" && path === "profile") {
			return true;
		}
		return location.pathname.includes(path);
	};

	const handleLogout = () => {
		// Dispatch the logout action
		dispatch(logout());
		// Close the modal
		setShowLogoutModal(false);
	};
	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	if (userLoading) {
		return <Spinner />;
	}

	return (
		<>
			{isLoggedIn ? (
				<div className="grid pb-20 minPhone:pt-28 xl:pt-32 minPhone:px-4 xl:gap-10 xl:px-32 minPhone:gap-4">
					<Htag tag="h1" type="regular">
						{getTitleFromPath(location.pathname)}
					</Htag>
					<nav>
						<ul className="grid gap-4 border-b grid-cols-maxThree place-items-center border-dividerColor">
							<li
								className={`border-b-2 ${
									isActiveTab("profile")
										? "border-mainColor"
										: "border-transparent"
								}`}
							>
								<Link to="profile">
									<P
										size="p2"
										type={isActiveTab("profile") ? "regular" : "light"}
										className="py-4 cursor-pointer"
									>
										{t("another.profile")}
									</P>
								</Link>
							</li>
							<li
								className={`border-b-2 ${
									isActiveTab("orders")
										? "border-mainColor"
										: "border-transparent"
								}`}
							>
								<Link to="orders">
									<P
										size="p2"
										type={isActiveTab("orders") ? "regular" : "light"}
										className="py-4 cursor-pointer"
									>
										{t("another.orders")}
									</P>
								</Link>
							</li>
							<li className="border-b-2 border-transparent">
								<button onClick={() => setShowLogoutModal(true)}>
									<P size="p2" type="light" className="py-4 cursor-pointer">
										{t("another.logout")}
									</P>
								</button>
							</li>
						</ul>
						<Outlet />
					</nav>

					{showLogoutModal && (
						<Modal
							onClose={() => setShowLogoutModal(false)}
							isOpen={showLogoutModal}
							title={t("titles.logout")}
							onCancel={() => setShowLogoutModal(false)}
							onConfirm={handleLogout}
						/>
					)}
				</div>
			) : (
				<div className="pt-32 pb-20 minPhone:px-6 lg:px-32">
					<Htag tag="h1" type="regular">
						{t("another.account")}
					</Htag>
					<div className="grid min-h-[60vh] gap-4 place-items-center place-content-center">
						<NoAccountIcon />
						<Htag tag="h2" type="light">
							{t("another.noAccount")}
						</Htag>
						<Link to={routeConstants.login.route} className="w-full">
							<Button appearance="black" width="full">
								{t("another.signIn")}
							</Button>
						</Link>
					</div>
				</div>
			)}
		</>
	);
};

export default AccountPage;
