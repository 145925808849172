import { createSlice } from "@reduxjs/toolkit";
import { fetchAllSliders } from "./sliderAsyncThunks";

type SliderState = {
	images: any[];
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null | undefined;
};

const sliderSlice = createSlice({
	name: "slider",
	initialState: {
		images: [],
		status: "idle",
		error: null,
	} as SliderState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllSliders.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchAllSliders.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.images = action.payload.data;
			})
			.addCase(fetchAllSliders.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export default sliderSlice.reducer;
