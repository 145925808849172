import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBestSellingProducts } from "./bestSellingAsyncThunks";

// Define a type for your state
type BestSellingState = {
	bestSelling: any[]; // Replace 'any' with a more specific type if possible
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: BestSellingState = {
	bestSelling: [],
	status: "idle",
	error: null,
};

const bestSellingSlice = createSlice({
	name: "bestSelling",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBestSellingProducts.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchBestSellingProducts.fulfilled,
				(state, action: PayloadAction<any[]>) => {
					// Replace 'any[]' with a more specific type if possible
					state.status = "succeeded";
					state.bestSelling = action.payload;
				}
			)
			.addCase(fetchBestSellingProducts.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message ?? null;
			});
	},
});

export default bestSellingSlice.reducer;
