/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { P } from "common/P/P";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import Accordion from "common/Accordion/Accordion";
import { Button } from "common/Button/Button";
import { Htag } from "common/Htag/Htag";
import WishlistButton from "common/WishlistButton/WishlistButton";

import Carousel from "components/Carousel/Carousel";
import ProductCard from "components/ProductCard/ProductCard";
import { productService } from "services/productService";
import { serviceConfig } from "configs";
import { AppDispatch, RootState } from "components/App/store";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "redux/cart/cartSlice";
import Spinner from "modules/loaders/Spinner/Spinner";
import splitTextBySeparator from "utils/splitTextBySymbol";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";
import { addWishlistItem, removeWishlistItem } from "redux/auth/authSlice";
import { addToWishlist, removeFromWishlist } from "services/wishlistService";
import { toast } from "react-toastify";

interface ProductDetailsProps {}

interface Product {
	_id: string;
	name: { tk: string; ru: string; en: string };
	description: { tk: string; ru: string; en: string };
	features: { tk: string; ru: string; en: string };
	delivery_info: { tk: string; ru: string; en: string };
	return_policy: { tk: string; ru: string; en: string };
	categoryId: any; // Update as per your actual Category structure
	colors: any[];
	bestSelling: boolean;
	isActive: boolean;
	createdAt: string;
	updatedAt: string;
	subCategory?: any; // Update as per your actual SubCategory structure
	subSubCategory?: any; // Update as per your actual SubSubCategory structure
	// Add other fields as necessary
}

const ProductDetails: React.FC<ProductDetailsProps> = () => {
	const { id } = useParams<{ id: string }>();
	const [product, setProduct] = useState<Product | null>(null);
	const [similarProducts, setSimilarProducts] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [selectedColor, setSelectedColor] = useState<any>(null);
	const [selectedSize, setSelectedSize] = useState<any>(null);
	const { t } = useTranslation();
	console.log("SelectedCOlor", selectedColor, selectedSize);

	const dispatch = useDispatch<AppDispatch>();

	const cartItems = useSelector((state: RootState) => state.cart.items);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const userWishlist = useSelector(
		(state: RootState) => state.auth.user?.wishlist
	);

	// Determine if the product is in the wishlist and get the wishlist ID
	const wishlistItem = userWishlist?.find(
		(item) => item.productId._id === product?._id
	);
	const isWished = Boolean(wishlistItem);
	const wishId = wishlistItem?._id;

	const isInCart = () => {
		return product && selectedColor && selectedSize
			? cartItems.some(
					(cartItem: any) =>
						cartItem.product._id === product._id &&
						cartItem.selectedColorId._id === selectedColor._id &&
						cartItem.selectedSizeId.sizeId._id === selectedSize.sizeId._id
			  )
			: false;
	};

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const handleCartAction = () => {
		if (!selectedSize || !selectedSize.sizeId || selectedSize.stock === 0) {
			// Show a toast message if no size is selected or if the selected size is out of stock
			toast.error(t("messages.sizeRequiredError"));
			return;
		}
		if (product) {
			if (isInCart()) {
				// Assuming selectedColor and selectedSize are objects with colorId and sizeId properties respectively
				const selectedColorId = selectedColor._id;
				const selectedSizeId = selectedSize.sizeId || "Default Size";

				dispatch(
					removeItem({
						productId: product._id,
						selectedColorId: selectedColorId,
						selectedSizeId: selectedSize._id,
					})
				);
			} else {
				const cartItem = {
					product: product,
					selectedColorId: selectedColor, // Assuming colorId is an object with a colorId property
					selectedSizeId: selectedSize, // Assuming sizeId is an object with a sizeId property
					quantity: 1,
					price: discountPrice, // discountedPrice needs to be defined or calculated
				};
				dispatch(addItem(cartItem));
			}
		}
	};

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				setLoading(true);
				const data = await productService.getProductById(id);
				setProduct(data.product);
				setSimilarProducts(data.similarProducts);

				// Set the first color as the selected color after fetching the product
				if (data && data.product.colors && data.product.colors.length > 0) {
					const firstColor = data.product.colors[0];
					setSelectedColor(firstColor);

					// Set the first size of the first color as the selected size
					const firstSize =
						firstColor.clothingSizes[0] || firstColor.shoesSizes[0] || null;
					setSelectedSize(firstSize);
				}
			} catch (error) {
				console.error("Error fetching product data", error);
				setError("Failed to load product details.");
			} finally {
				setLoading(false);
			}
		};

		if (id) {
			fetchProduct();
		}
	}, [id]);

	if (loading) {
		<Spinner />;
	}

	if (error) {
		return <div>{error}</div>;
	}

	if (!product) {
		return <div>Product not found</div>;
	}

	const firstColor = product.colors[0] || {};
	const firstSize =
		firstColor.clothingSizes[0] || firstColor.shoesSizes[0] || {};
	const currentColorImages =
		selectedColor?.images || product.colors[0]?.images || [];
	const currentSize = selectedSize || firstSize;
	const price = currentSize.price || "N/A";
	const isDiscounted = currentSize.discounted || false;
	const discountPrice = currentSize.discount_price || price;
	const discountPercentage = currentSize.discount_percentage || 0;

	const renderProductCard = (product: any) => {
		// Check if product and product.colors are defined
		if (!product || !product.colors || product.colors.length === 0) return null;

		let displayColor = product.colors[0];

		// Check if displayColor has images and sizes
		if (!displayColor.images || displayColor.images.length === 0) return null;
		if (!displayColor.clothingSizes && !displayColor.shoesSizes) return null;

		const firstImage = displayColor.images[0];
		const secondImage = displayColor.images[1] || firstImage; // Use first image as fallback for second image
		const firstSize =
			displayColor.clothingSizes[0] || displayColor.shoesSizes[0] || {};

		const price = firstSize.price || "N/A"; // Use 'N/A' if price is not available
		const isDiscounted = firstSize.discounted || false;
		const discountPercentage = firstSize.discount_percentage || 0;

		return (
			<ProductCard
				key={product._id}
				imgSrc={[firstImage, secondImage]}
				name={getTranslation(product.name)}
				price={price}
				discount={isDiscounted}
				discountPercentage={discountPercentage}
				id={product._id}
				item={product}
			/>
		);
	};

	const renderSizes = (color: any) => {
		const sizes =
			color.clothingSizes.length > 0 ? color.clothingSizes : color.shoesSizes;

		return sizes.map((size: any, idx: number) => (
			<StyleManagerItem
				key={idx}
				label={size.sizeId.size}
				onClick={() => setSelectedSize(size)}
				className="inline-block"
				disabled={size.stock === 0}
				selected={selectedSize && selectedSize.sizeId === size.sizeId}
			/>
		));
	};

	return (
		<div className="grid minPhone:gap-6 minPhone:px-4 minPhone:pt-28 minPhone:pb-6 xl:gap-10 xl:px-32 xl:pt-32 xl:pb-20">
			<P size="p3" type="light">
				{t("another.productDetails")}
			</P>
			<div className="grid minPhone:grid-cols-1 minPhone:gap-4 xl:grid-cols-2 xl:gap-10">
				<div className="grid minPhone:gap-4 xl:gap-10">
					{currentColorImages.map((image: any, idx: any) => (
						<img
							key={idx}
							src={`${serviceConfig.apiUrl}${image}`}
							alt={`${product.name.en} - Image ${idx + 1}`}
							className="w-full xl:h-[891px] object-cover"
						/>
					))}
				</div>
				<div className="grid grid-flow-row minPhone:gap-3 xl:gap-8 auto-rows-max">
					<div className="grid gap-3">
						<Htag tag="h2" type="light">
							{getTranslation(product.name)}
						</Htag>

						{firstSize.discounted && (
							<span className="px-2 py-1 rounded-sm bg-mainColor w-max">
								<P size="p2" type="light" className="text-white">
									{firstSize.discount_percentage}%
								</P>
							</span>
						)}

						{isDiscounted && firstSize.discount_percentage > 0 ? (
							<span className="grid gap-2 grid-cols-maxTwo place-items-center">
								<P
									size="p2"
									type="light"
									className="line-through minPhone:text-sm xl:text-base"
								>
									{price} {serviceConfig.currencySign}
								</P>
								<Htag
									tag="h2"
									type="light"
									className="minPhone:text-base xl:text-2xl"
								>
									{discountPrice} {serviceConfig.currencySign}
								</Htag>
							</span>
						) : (
							<Htag
								tag="h2"
								type="light"
								className="minPhone:text-base xl:text-2xl"
							>
								{price} {serviceConfig.currencySign}
							</Htag>
						)}
					</div>
					<div className="grid gap-6">
						<div className="grid minPhone:gap-2 xl:gap-4">
							<P size="p2" type="light">
								{t("another.colors")}
							</P>
							<div className="flex flex-wrap minPhone:gap-2 xl:gap-4">
								{product.colors.map((color: any, idx: number) => (
									<StyleManagerItem
										key={idx}
										label={getTranslation(color.name)}
										onClick={() => {
											setSelectedColor(color);
											setSelectedSize(
												color.clothingSizes[0] || color.shoesSizes[0] || null
											);
										}}
										className="inline-block"
										disabled={color.total_stock === 0}
										selected={selectedColor && selectedColor._id === color._id}
									/>
								))}
							</div>
						</div>
						<div className="grid gap-6">
							{selectedColor && (
								<div className="grid minPhone:gap-2 xl:gap-4">
									<P size="p2" type="light">
										{t("another.sizes")}
									</P>
									<div className="flex flex-wrap minPhone:gap-2 xl:gap-4">
										{renderSizes(selectedColor)}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="grid minPhone:gap-3 xl:gap-4 grid-cols-frMax">
						<Button
							appearance="white"
							width="full"
							paddingY="minPhone:py-3 xl:py-4"
							onClick={handleCartAction}
						>
							{isInCart() ? t("another.remove") : t("another.addToBag")}
						</Button>
						{isLoggedIn && (
							<WishlistButton
								paddingX="minPhone:px-3 xl:px-4"
								isActive={isWished}
								wished={isWished}
								productId={product?._id}
								wishId={wishId}
								product={product}
							/>
						)}
					</div>
					<div className="grid gap-2">
						<Accordion
							title={t("another.productDetails")}
							content={getTranslation(product.description)} // Assuming `description` has the details
						/>
						<Accordion
							title={t("another.productFeatures")}
							content={getTranslation(product.features)}
						/>
						<Accordion
							title={t("another.deliveryAndReturn")}
							content={getTranslation(product.delivery_info)}
						/>
						{/* <Accordion
							title="Delivery and Returns"
							content={
								product.delivery_info.en + " " + product.return_policy.en
							}
						/> */}
					</div>
				</div>
			</div>
			<div className="grid max-w-full overflow-hidden minPhone:gap-6 xl:gap-14">
				<Htag tag="h2" type="regular">
					{t("another.similarProducts")}
				</Htag>
				<Carousel>
					{similarProducts.map((product: any) => renderProductCard(product))}
				</Carousel>
			</div>
		</div>
	);
};

export default ProductDetails;
