import axios from "axios";
import { serviceConfig } from "configs";

const getAllCompanies = async () => {
	try {
		const response = await axios.get(`${serviceConfig.apiUrl}/company`);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

export const companyService = {
	getAllCompanies,
};
