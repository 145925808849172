import React from "react";
import { CounterButtonProps } from "./CounterButton.props";

import { ReactComponent as MinusIcon } from "common/assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { P } from "common/P/P";

const CounterButton = ({
	className,
	counter,
	onDecrease,
	onIncrease,
	onBelowLimit,
	...props
}: CounterButtonProps): JSX.Element => {
	const handleDecrease = () => {
		if (counter <= 1) {
			onBelowLimit();
		} else {
			onDecrease();
		}
	};

	return (
		<div
			className={`border border-dividerColor rounded-sm grid grid-cols-maxThree place-items-center ${className}`}
			{...props}
		>
			<div className="px-1 py-1">
				<MinusIcon
					onClick={handleDecrease}
					className="w-5 h-5 cursor-pointer"
				/>
			</div>
			<div className="px-3 py-1 border-x border-dividerColor ">
				<P size="p3" type="light">
					{counter}
				</P>
			</div>
			<div className="px-1 py-1">
				<PlusIcon onClick={onIncrease} className="w-5 h-5 cursor-pointer" />
			</div>
		</div>
	);
};

export default CounterButton;
