import React, { useState, useEffect, useLayoutEffect } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Htag } from "common/Htag/Htag";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

interface OTPModalProps {
	onClose: () => void;
	isOpen: boolean;
	onConfirm?: any;
}

const OTPModal: React.FC<OTPModalProps> = ({ onClose, isOpen, onConfirm }) => {
	const [otp, setOtp] = useState("");
	const [timer, setTimer] = useState<number | null>(null);
	const { t } = useTranslation();

	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	const inputRefs = Array(5)
		.fill(0)
		.map(() => React.createRef<HTMLInputElement>());

	const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		// Only allow numeric values and ensure the length doesn't exceed 6
		if (/^\d{0,6}$/.test(value)) {
			setOtp(value);
		}
	};

	const formatTimer = (time: number): string => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const startTimer = () => {
		let time = 30;
		setTimer(time);
		const interval = setInterval(() => {
			time--;
			setTimer(time);
			if (time <= 0) {
				clearInterval(interval);
				setTimer(null);
			}
		}, 1000);
	};

	useEffect(() => {
		startTimer();
		return () => {
			setTimer(null);
		};
	}, []);

	const modalVariants = {
		open: { opacity: 1, transition: { duration: 0.3 } },
		closed: { opacity: 0, transition: { duration: 0.3 } },
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-50" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					initial="closed"
					animate="open"
					exit="closed"
					variants={modalVariants}
					className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 minPhone:w-[95%] lg:w-[610px] bg-white flex flex-col z-50 rounded-lg shadow-lg"
				>
					<CloseIcon
						onClick={onClose}
						className="absolute cursor-pointer right-4 top-4"
					/>
					<div className="grid gap-10 minPhone:p-8 lg:p-16">
						<div className="grid gap-4 place-content-center">
							<div className="p-4 overflow-y-auto max-h-40">
								<Htag
									tag="h2"
									type="regular"
									className="mb-4 text-center break-words"
								>
									{t("another.enterVerification")}
								</Htag>
								<P size="p3" type="light" className="text-center break-words">
									{t("another.verificationMessage")}
								</P>
							</div>
						</div>
						<div className="grid gap-6 place-content-center">
							<Input
								type="text"
								maxLength={6}
								value={otp}
								minWidth={false}
								width="w-full"
								onChange={handleOtpChange}
								appearance="default"
								name="otp"
								placeholder={t("placeholders.code")}
							/>
							<div className="grid gap-2 grid-cols-maxTwo place-self-center">
								{!timer && (
									<P size="p3" type="light">
										{t("another.didntGetCode")}
									</P>
								)}

								<P className="text-center" size="p3" type="light">
									{timer ? (
										formatTimer(timer)
									) : (
										<P
											onClick={startTimer}
											size="p3"
											type="regular"
											className="cursor-pointer"
										>
											{t("another.clickResend")}
										</P>
									)}
								</P>
							</div>
							<Button
								appearance="white"
								onClick={() => onConfirm(otp)}
								paddingX="minPhone:px-20 lg:px-28"
								disabled={otp.length !== 6}
							>
								{t("another.confirm")}
							</Button>
						</div>
					</div>
				</motion.div>
			)}
		</>
	);
};

export default OTPModal;
