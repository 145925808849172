import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { auth } from "./authAsyncThunks";
import { checkTokenValidity, deleteCookie, getCookie } from "utils/cookie";

interface User {
	_id: string;
	email: string;
	role: string;
	addresses: any[];
	wishlist: any[];
	orderHistory: any[];
	username: string;
	phoneNumber: string;
}

interface AuthState {
	isLoggedIn: boolean;
	user: User | null;
	error: string | null | undefined;
	loading: boolean;
}

const initialState: AuthState = {
	isLoggedIn: checkTokenValidity(),
	user: checkTokenValidity()
		? getCookie("user")
			? JSON.parse(getCookie("user") || "{}")
			: null
		: null,
	error: null,
	loading: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.isLoggedIn = true;
			state.user = action.payload;
			state.error = null;
		},
		loginFailed: (state, action) => {
			state.error = action.payload;
		},
		logout: (state) => {
			deleteCookie("token");
			deleteCookie("user");
			state.isLoggedIn = false;
			state.user = null;
		},
		updateAddresses: (state, action: PayloadAction<any[]>) => {
			if (state.user) {
				state.user.addresses = action.payload;
			}
		},
		addWishlistItem: (state, action: PayloadAction<any>) => {
			if (state.user) {
				state.user.wishlist.push(action.payload);
			}
		},
		removeWishlistItem: (state, action: PayloadAction<string>) => {
			if (state.user) {
				state.user.wishlist = state.user.wishlist.filter(
					(item) => item._id !== action.payload
				);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(auth.pending, (state) => {
				state.loading = true;
			})
			.addCase(auth.fulfilled, (state, action) => {
				state.isLoggedIn = true;
				state.user = action.payload.user;
				state.error = null;
				state.loading = false;
			})
			.addCase(auth.rejected, (state, action) => {
				state.error = action.error.message || null;
				state.loading = false;
			});
	},
});

export const {
	setUser,
	logout,
	updateAddresses,
	addWishlistItem,
	removeWishlistItem,
} = authSlice.actions;
export default authSlice.reducer;
