import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryService } from "services/categoryService";

export const fetchAllCategories = createAsyncThunk(
	"categories/fetchAll",
	async () => {
		const response = await categoryService.getAllCategories();
		return response.data;
	}
);
