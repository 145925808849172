import React, { useEffect, useLayoutEffect } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { Htag } from "common/Htag/Htag";
import { motion } from "framer-motion";
import { NotificationModalProps } from "./NotificationModal.props";

const NotificationModal: React.FC<NotificationModalProps> = ({
	isOpen,
	onClose,
	title,
	width,
	icon,
}) => {
	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			const timer = setTimeout(() => {
				if (onClose) onClose();
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [isOpen, onClose]);

	const modalVariants = {
		open: { opacity: 1, transition: { duration: 0.3 } },
		closed: { opacity: 0, transition: { duration: 0.3 } },
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-50" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					initial="closed"
					animate="open"
					exit="closed"
					variants={modalVariants}
					className={`fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 ${
						width ? width : "minPhone:w-[95%] maxPhone:w-[70%] md:w-[610px]"
					} bg-white flex flex-col z-50 rounded-lg shadow-lg`}
				>
					<div className="flex items-center bg-white rounded-t-lg minPhone:p-2 lg:p-4 place-self-end ">
						<CloseIcon onClick={onClose} className="cursor-pointer" />
					</div>
					<div className="grid gap-3">
						<div className="grid flex-grow gap-4 py-8 overflow-y-auto minPhone:px-8 lg:px-20 place-content-center place-items-center">
							{icon}
							<div className={``}>
								<Htag
									tag="h2"
									type="regular"
									className="text-center cursor-default text-passiveTextColor"
								>
									{title}
								</Htag>
							</div>
						</div>
					</div>
				</motion.div>
			)}
		</>
	);
};

export default NotificationModal;
