import axios from "axios";
import { serviceConfig } from "configs";

const getAddressForUser = async (userId: string) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/user/${userId}/addresses`
		);
		return response.data;
	} catch (error: any) {
		throw error.response?.data || "An error occurred";
	}
};

const addAddressForUser = async (userId: string, addressData: any) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/user/${userId}/address`,
			addressData
		);
		return response.data;
	} catch (error: any) {
		throw error.response?.data || "An error occurred";
	}
};

const editAddress = async (addressId: string, updatedData: any) => {
	try {
		const response = await axios.put(
			`${serviceConfig.apiUrl}/address/${addressId}`,
			updatedData
		);
		return response.data;
	} catch (error: any) {
		throw error.response?.data || "An error occurred";
	}
};

const deleteAddress = async (addressId: string) => {
	try {
		const response = await axios.delete(
			`${serviceConfig.apiUrl}/address/${addressId}`
		);
		return response.data;
	} catch (error: any) {
		throw error.response?.data || "An error occurred";
	}
};

export const addressService = {
	getAddressForUser,
	addAddressForUser,
	editAddress,
	deleteAddress,
};
