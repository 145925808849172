import React, { useLayoutEffect } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { motion } from "framer-motion";
import { ManagePanelProps } from "./ManagePanel.props";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { useTranslation } from "react-i18next";

const ManagePanel: React.FC<ManagePanelProps> = ({
	isOpen,
	onClose,
	label,
	onCheckout,
	children,
	width,
	cart,
}) => {
	const cartItems = useSelector((state: RootState) => state.cart.items);
	console.log("CARTTT", cart, cartItems);
	const { t } = useTranslation();
	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	const panelVariants = {
		open: { x: 0, transition: { duration: 0.3 } },
		closed: { x: "100%", transition: { duration: 0.3 } },
		exit: { x: "100%", transition: { duration: 0.3 } },
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-50" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					key="managePanel"
					initial="closed"
					animate="open"
					exit="exit"
					variants={panelVariants}
					className={`fixed top-0 bottom-0 right-0 ${
						width ? width : "minPhone:w-full xl:w-[475px]"
					} bg-whiteColor flex flex-col z-50`}
				>
					<div className="z-10 flex gap-6 py-6 bg-white border-b minPhone:px-4 xl:px-8 border-dividerColor place-items-center place-content-between">
						<Htag tag="h3" type="regular" className="cursor-default">
							{label}
						</Htag>
						<CloseIcon onClick={onClose} className="cursor-pointer" />
					</div>
					<div className="flex-grow py-6 overflow-y-auto bg-white minPhone:px-4 xl:px-8">
						{children}
					</div>
					{cart && cartItems.length > 0 && (
						<div className="grid w-full gap-6 px-8 py-6 bg-white border-t grid-cols border-dividerColor">
							<Button appearance="white" onClick={onCheckout} width="w-full">
								{t("another.checkout")}
							</Button>
						</div>
					)}
				</motion.div>
			)}
		</>
	);
};

export default ManagePanel;
