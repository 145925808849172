import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSearchResults } from "./searchAsyncThunks";

// Define a type for your state
type SearchState = {
	searchResults: any[]; // Replace 'any' with a more specific type if possible
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: SearchState = {
	searchResults: [],
	status: "idle",
	error: null,
};

const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		// Define any reducers you need here
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSearchResults.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchSearchResults.fulfilled,
				(state, action: PayloadAction<any[]>) => {
					// Replace 'any[]' with a more specific type if possible
					state.status = "succeeded";
					state.searchResults = action.payload;
				}
			)
			.addCase(fetchSearchResults.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message ?? null;
			});
	},
});

export default searchSlice.reducer;
