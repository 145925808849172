import { Button } from "common/Button/Button";
import OrderStatus from "common/OrderStatus/OrderStatus";
import { P } from "common/P/P";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import { orderService } from "services/orderService";

interface OrdersTableProps {
	orders: any;
	onRowClick: (order: any) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, onRowClick }) => {
	const { t } = useTranslation();
	const handleCancelOrder = async (
		event: React.MouseEvent<HTMLButtonElement>,
		order: any
	) => {
		event.stopPropagation();
		if (order.orderStatus === "Waiting") {
			try {
				const updatedOrder = await orderService.updateUserOrder(
					order.userId,
					order._id,
					{ orderStatus: "Cancelled" }
				);
				window.location.reload();
				// Update the state or re-fetch orders to reflect the changes
			} catch (error) {
				console.error("Error cancelling order:", error);
			}
		}
	};
	return (
		<table className="w-full border rounded-lg border-dividerColor">
			<thead className="bg-white border border-dividerColor">
				<tr className="bg-mainColor">
					<th className="px-5 py-5 text-left border-b border-dividerColor">
						<P size="p3" type="regular" className="text-white">
							{t("another.orderId")}
						</P>
					</th>
					<th className="px-5 py-4 text-left border-b border-dividerColor">
						<P size="p3" type="regular" className="text-white">
							{t("another.date")}
						</P>
					</th>
					<th className="px-5 py-4 text-left border-b border-dividerColor">
						<P size="p3" type="regular" className="text-white">
							{t("another.totalPrice")}
						</P>
					</th>
					<th className="px-5 py-4 text-left border-b border-dividerColor">
						<P size="p3" type="regular" className="text-white">
							{t("another.status")}
						</P>
					</th>
					<th className="w-16 px-5 py-4 text-left border-b border-dividerColor">
						<P size="p3" type="regular" className="text-white">
							{t("another.cancelOrder")}
						</P>
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{orders.map((order: any, idx: any) => (
					<tr
						key={order.id}
						className={`${
							idx === orders.length - 1
								? "border-0"
								: "border-b border-dividerColor"
						} ${idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"} cursor-pointer`}
						onClick={() => onRowClick(order)}
					>
						<td
							className={`px-5 py-4 text-textColor font-medium ${
								idx === orders.length - 1 ? "rounded-bl-lg" : ""
							}`}
						>
							<P size="p3" type="light">
								{order._id}
							</P>
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							<P size="p3" type="light">
								{new Date(order.orderDate)
									.toLocaleDateString("en-GB", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
									})
									.replace(/\//g, ".")}
							</P>
						</td>

						<td
							className={`px-5 py-4 text-textColor font-regular ${
								idx === orders.length - 1 ? "rounded-br-lg" : ""
							}`}
						>
							<P size="p3" type="light">
								{order.orderSummary.total} {serviceConfig.currencySign}
							</P>
						</td>
						<td className="px-5 py-4">
							<OrderStatus status={order.orderStatus} />
						</td>

						<td className="px-5 py-4">
							<Button
								appearance={order.orderStatus === "Waiting" ? "black" : "white"}
								className={
									order.orderStatus === "Waiting"
										? "cursor-pointer hover:bg-grayOne"
										: "cursor-not-allowed"
								}
								paddingX="px-6"
								grid="grid"
								onClick={(event) => handleCancelOrder(event, order)}
								disabled={order.orderStatus !== "Waiting"}
							>
								{t("another.cancel")}
							</Button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default OrdersTable;
