import React from "react";
import "./Spinner.css"; // Import the custom CSS

const Spinner = ({ inModal, width = "w-full" }: any) => {
	return (
		<div
			className={`flex items-center justify-center ${width} ${
				inModal ? "bg-[#f3f3f3] rounded-lg py-3 px-16" : "h-screen"
			} `}
		>
			<div className={`${inModal ? "modalLoader" : "loader"}`}></div>
		</div>
	);
};

export default Spinner;
