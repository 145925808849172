import React, { useEffect } from "react";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { P } from "common/P/P";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "components/App/store";
import { fetchAllCompanies } from "redux/company/companyAsyncThunks";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const Footer: React.FC = (): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const companies = useSelector((state: RootState) => state.company.companies);
	const companyStatus = useSelector((state: RootState) => state.company.status);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (companyStatus === "idle") {
			dispatch(fetchAllCompanies());
		}
	}, [dispatch, companyStatus]);

	const currentLang = localStorage.getItem("i18nextLng");
	return (
		<div className="bg-mainColor minPhone:py-14 minPhone:px-5 minPhone:grid-cols-1 minPhone:gap-14 lg:py-[72px] lg:px-[104px] grid lg:grid-cols-5 lg:gap-8">
			<div className="grid gap-1 grid-rows-maxTwo">
				<Logo className="h-6 fill-white" />
				<P size="p3" type="light" className="text-dividerColor">
					{t("another.clothingBrand")}
				</P>
			</div>
			{companies &&
				companies.length > 0 &&
				companies.map((company) => (
					<div className="grid gap-8" key={company._id}>
						<P size="p1" type="regular" className="text-white">
							{company.name}
						</P>
						<div className="grid gap-4">
							<P size="p2" type="light" className="text-white">
								{currentLang === "en"
									? company.address_en
									: currentLang === "ru"
									? company.address_ru
									: currentLang === "tk"
									? company.address_tkm
									: null}
							</P>
							<P size="p2" type="light" className="text-white">
								{company.phoneNumber}
							</P>
							<P size="p2" type="light" className="text-white">
								{company.instagramLink}
							</P>
						</div>
					</div>
				))}
			<div className="grid gap-8 grid-rows-maxTwo">
				<P
					size="p1"
					type="regular"
					className="text-white cursor-pointer"
					onClick={() => navigate(routeConstants.termsConditions.route)}
				>
					{t("another.termsAndConditions")}
				</P>
				<P
					size="p1"
					type="regular"
					className="text-white cursor-pointer"
					onClick={() => navigate(routeConstants.about.route)}
				>
					{t("another.aboutus")}
				</P>
				<P
					size="p1"
					type="regular"
					className="text-white cursor-pointer"
					onClick={() => navigate(routeConstants.contact.route)}
				>
					{t("another.contactus")}
				</P>
			</div>
		</div>
	);
};

export default Footer;
