import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CartItem {
	product: any; // Use a more specific type if available
	selectedColorId: any;
	selectedSizeId: any;
	quantity: number;
	price: number;
}

interface CartState {
	items: CartItem[];
	totalAmount: number;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
}

const initialState: CartState = {
	items: [],
	totalAmount: 0,
	status: "idle",
	error: null,
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addItem: (state, action: PayloadAction<CartItem>) => {
			// Check if the item already exists with the same color and size
			const existingItem = state.items.find(
				(item) =>
					item.product._id === action.payload.product._id &&
					item.selectedColorId === action.payload.selectedColorId &&
					item.selectedSizeId === action.payload.selectedSizeId
			);

			if (existingItem) {
				existingItem.quantity += action.payload.quantity;
			} else {
				state.items.push(action.payload);
			}
			state.totalAmount += action.payload.price * action.payload.quantity;
		},
		increaseQuantity: (
			state,
			action: PayloadAction<{
				productId: string;
				selectedColorId: string | { _id: string };
				selectedSizeId: string | { _id: string };
			}>
		) => {
			const { productId, selectedColorId, selectedSizeId } = action.payload;
			console.log("DATA", productId, selectedColorId, selectedSizeId);

			const item = state.items.find(
				(item) =>
					item.product._id === productId &&
					item.selectedColorId._id === selectedColorId &&
					item.selectedSizeId._id === selectedSizeId
			);

			if (item) {
				item.quantity += 1;
				state.totalAmount += item.price;
			}
		},

		decreaseQuantity: (
			state,
			action: PayloadAction<{
				productId: string;
				selectedColorId: string | any;
				selectedSizeId: string;
			}>
		) => {
			const { productId, selectedColorId, selectedSizeId } = action.payload;
			const itemIndex = state.items.findIndex(
				(item: any) =>
					item.product._id === productId &&
					item.selectedColorId._id === selectedColorId &&
					item.selectedSizeId._id === selectedSizeId
			);
			if (itemIndex !== -1 && state.items[itemIndex].quantity > 1) {
				state.items[itemIndex].quantity -= 1;
				state.totalAmount -= state.items[itemIndex].price;
			} else if (itemIndex !== -1) {
				state.totalAmount -=
					state.items[itemIndex].price * state.items[itemIndex].quantity;
				state.items.splice(itemIndex, 1);
			}
		},
		removeItem: (
			state,
			action: PayloadAction<{
				productId: string;
				selectedColorId: string | any;
				selectedSizeId: string | { _id: string };
			}>
		) => {
			const { productId, selectedColorId, selectedSizeId } = action.payload;

			console.log("INSIDE", productId, selectedColorId, selectedSizeId);

			const itemToRemove = state.items.find(
				(item: any) =>
					item.product._id === productId &&
					item.selectedColorId._id === selectedColorId &&
					item.selectedSizeId._id === selectedSizeId
			);
			console.log("ITEM REMOVE", itemToRemove);

			if (itemToRemove) {
				state.totalAmount -= itemToRemove.price * itemToRemove.quantity;
				state.items = state.items.filter((item: any) => item !== itemToRemove);
			}
		},

		clearCart: (state) => {
			state.items = [];
			state.totalAmount = 0;
		},
		// Additional reducers as needed
	},
	// extraReducers for async thunks if needed
});

export const {
	addItem,
	increaseQuantity,
	decreaseQuantity,
	removeItem,
	clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
