import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "i18n";
import { useTranslation } from "react-i18next";
import { ToastContainer, Zoom } from "react-toastify";

import { AppRoutes, routeConstants } from "navigation";
import { ErrorBoundary } from "modules/errors";

import Footer from "components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import MobileNavbar from "components/Navbar/MobileNavbar";
import { Provider } from "react-redux";
import store from "./store";
import Spinner from "modules/loaders/Spinner/Spinner";
import { serviceConfig } from "configs";
import useBlockLogs from "hooks/useBlockLogs";

const App: React.FC = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const showLogs = serviceConfig.show_logs;

	useBlockLogs(showLogs);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<ErrorBoundary>
			<Provider store={store}>
				<Router>
					<ToastContainer
						position="top-right"
						autoClose={3000}
						limit={3}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
						transition={Zoom}
					/>
					{windowWidth >= 1024 ? <Navbar /> : <MobileNavbar />}
					<div className="!min-h-screen">
						<AppRoutes />
					</div>
					<Footer />
				</Router>
			</Provider>
		</ErrorBoundary>
	);
};

export default App;
