import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";

import { ReactComponent as InfoIcon } from "common/assets/icons/warning-circle.svg";
import { ReactComponent as SuccessIcon } from "common/assets/icons/success_icon.svg";
import { Button } from "common/Button/Button";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import RadioButton from "common/RadioButton/RadioButton";
import CartBody from "components/Cart/CartBody";
import { RootState } from "components/App/store";
import { useDispatch, useSelector } from "react-redux";
import { addressService } from "services/addressService";
import { updateAddresses } from "redux/auth/authSlice";
import { orderService } from "services/orderService";
import { clearCart } from "redux/cart/cartSlice";
import NotificationModal from "common/NotificationModal/NotificationModal";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { serviceConfig } from "configs";

const deliveryTime = [
	"09:00/11:00",
	"11:00/13:00",
	"13:00/15:00",
	"15:00/17:00",
	"17:00/19:00",
	"19:00/21:00",
];

const CheckoutPage: React.FC = (): JSX.Element => {
	const auth = useSelector((state: RootState) => state.auth.user);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const totalAmount = useSelector((state: RootState) => state.cart.totalAmount);
	const cartItems = useSelector((state: RootState) => state.cart.items);
	const cart = useSelector((state: RootState) => state.cart);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [selectedValue, setSelectedValue] = useState<string>("cash");
	const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>("");

	const [manualAddress, setManualAddress] = useState<string>("");
	const [orderNotes, setOrderNotes] = useState<string>("");
	const [newAddress, setNewAddress] = useState("");
	const [isAddingAddress, setIsAddingAddress] = useState(false);
	const [username, setUsername] = useState(isLoggedIn ? auth?.username : "");
	const [phoneNumber, setPhoneNumber] = useState(
		isLoggedIn ? auth?.phoneNumber : ""
	);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isAddressLoading, setIsAddressLoading] = useState(false);

	const validateForm = () => {
		if (!username || !phoneNumber || !manualAddress) {
			toast.error(t("messages.enterAllFields"));
			return false;
		}

		return true;
	};

	const handleUsernameChange = (e: any) => {
		setUsername(e.target.value);
	};

	const handlePhoneNumberChange = (e: any) => {
		setPhoneNumber(e.target.value);
	};

	const handleDeliveryTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedDeliveryTime(e.target.value);
	};

	const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(e.target.value);
	};

	const handleAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setManualAddress(e.target.value);
	};

	const handleOrderNotesChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setOrderNotes(e.target.value);
	};

	const handleAddressSelect = (selectedAddress: string) => {
		setManualAddress(selectedAddress); // This sets the selected address into the manualAddress state.
		console.log("Selected Address:", selectedAddress);
	};

	// Calculates the subtotal of the cart items
	const calculateSubtotal = (cartItems: any) => {
		return cartItems.totalAmount;
	};

	// Calculates shipping charges (example: flat rate)
	const calculateShippingCharges = (cartItems: any) => {
		return 0; // Flat rate shipping charge, adjust as needed
	};

	// Calculates the total cost (subtotal + taxes + shipping charges)
	const calculateTotal = (cartItems: any) => {
		const subtotal = cart.totalAmount;
		const shippingCharges = calculateShippingCharges(cartItems);
		return subtotal + shippingCharges;
	};

	const resetFormAndCart = () => {
		// Reset local state
		setUsername(isLoggedIn ? auth?.username : "");
		setPhoneNumber(isLoggedIn ? auth?.phoneNumber : "");
		setManualAddress("");
		setOrderNotes("");
		setNewAddress("");
		setIsAddingAddress(false);
		setSelectedDeliveryTime("");
		setSelectedValue("cash");

		// Dispatch action to clear the cart
		dispatch(clearCart()); // Assuming `clearCart` is an action creator to reset the cart
	};

	const createOrderData = () => {
		// Calculate order summary (subtotal, taxes, shippingCharges, total)
		const subtotal = cartItems.reduce(
			(acc: any, item: any) => acc + item.unitPrice * item.quantity,
			0
		);
		const taxes = subtotal * 0.1; // Assuming 10% tax rate
		const shippingCharges = 50; // Fixed shipping charge
		const total = subtotal + taxes + shippingCharges;

		const mappedCartItems = cartItems.map((item: any) => ({
			product: {
				...item.product, // Spread operator to include all product fields
				// You can add or override any product fields here if necessary
			},
			quantity: item.quantity,
			unitPrice: item.price,
			totalPrice: item.price * item.quantity,
			selectedColorId: item.selectedColorId._id,
			selectedSizeId: item.selectedSizeId.sizeId._id,
		}));
		const orderData = {
			...(isLoggedIn && auth?._id ? { userId: auth._id } : {}),
			userName: username,
			email: isLoggedIn ? auth?.email : "notLoggedInUser@gmail.com",
			phoneNumber: phoneNumber,
			orderDate: new Date().toISOString(),
			orderStatus: "Waiting",
			payment: {
				method: selectedValue, // 'cash' or 'card'
				transactionId: "123456789", // Generate or fetch this appropriately
				status: "Pending",
			},
			shippingAddress: {
				fullName: username,
				address1: manualAddress,
				// Include other address fields if needed
			},
			products: mappedCartItems,
			orderSummary: {
				subtotal: calculateSubtotal(cart).toFixed(2),
				taxes: 0,
				shippingCharges: 0,
				total: calculateTotal(cartItems).toFixed(2),
			},
			notes: orderNotes + ` Доставить в ${selectedDeliveryTime}`,
			// Include any other fields needed for your order
		};

		return orderData;
	};

	const handleSaveAddress = async () => {
		if (newAddress && auth) {
			setIsAddressLoading(true);
			try {
				const addressData = {
					userId: auth._id,
					type: "default",
					address1: newAddress,
					address2: "",
					city: "Ashgabat",
					state: "",
					postalCode: "",
					country: "",
				};

				const response = await addressService.addAddressForUser(
					auth._id,
					addressData
				);

				if (response && response.data) {
					const updatedAddresses = auth.addresses
						? [...auth.addresses, response.data]
						: [addressData];
					dispatch(updateAddresses(updatedAddresses));
					toast.success(t("messages.addAddressSuccess"));

					setNewAddress("");
					setIsAddingAddress(false);
				} else {
					console.error("Failed to save new address");
					toast.error(t("messages.addAddressError"));
				}
			} catch (error) {
				console.error("Failed to add address:", error);
				toast.error(t("messages.addAddressError"));
			} finally {
				setIsAddressLoading(false);
			}
		}
	};

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const handleSubmitOrder = async () => {
		if (!validateForm()) return;

		setLoading(true);
		try {
			const orderData = createOrderData();
			const response = await orderService.createOrder(orderData);
			if (response && response.data) {
				setShowModal(true);
				toast.success(t("messages.orderSuccess"));
				resetFormAndCart();
				smoothScrollToTop();
			} else {
				toast.error("messages.orderError");
			}
		} catch (error) {
			console.error("Error submitting order:", error);
			toast.error(t("messages.orderFail"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="grid bg-white minPhone:gap-6 minPhone:pt-20 xl:gap-10 xl:pt-24 minPhone:px-4 xl:pl-28 minPhone:pb-6 xl:pb-0">
			<div className="grid minPhone:grid-cols-1 xl:grid-cols-[65%_35%] minPhone:gap-6 xl:gap-0">
				<div className="xl:pr-[110px] grid minPhone:gap-6 xl:gap-10 minPhone:pt-4 xl:pt-12 xl:pb-20">
					<Htag tag="h1" type="regular">
						{t("another.checkout")}
					</Htag>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="username"
						withlabel={true}
						label={t("another.username")}
						placeholder={t("another.username")}
						value={username}
						onChange={handleUsernameChange}
					/>

					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="phone_number"
						withlabel={true}
						label={t("another.phoneNumber")}
						placeholder={t("another.phoneNumber")}
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
					/>

					{isLoggedIn && (
						<Select
							label={t("another.address")}
							defaultSelectLabel={t("another.defaultAddress")}
							onSelect={handleAddressSelect}
						>
							{auth?.addresses.map((address, index) => {
								return (
									<SelectItem key={index} value={address.address1}>
										{address.address1}
									</SelectItem>
								);
							})}
						</Select>
					)}

					<div className="grid gap-6">
						<div className="grid gap-2">
							{isLoggedIn ? (
								<>
									<Textarea
										value={newAddress}
										name="address"
										onChange={(e) => setNewAddress(e.target.value)}
										placeholder={t("placeholders.address")}
										withlabel={true}
										label={t("another.addAddress")}
										rows={5}
									/>
									<div className="flex items-center gap-2">
										<InfoIcon />
										<P
											size="p3"
											type="light"
											className="flex-shrink text-grayTwo"
										>
											{t("titles.address")}
										</P>
									</div>
								</>
							) : (
								<Textarea
									value={manualAddress}
									name="address"
									onChange={handleAddressChange}
									placeholder={t("placeholders.address")}
									withlabel={true}
									label={t("another.addAddress")}
									rows={5}
								/>
							)}
						</div>
						{isLoggedIn &&
							(isAddressLoading ? (
								<Spinner inModal={true} />
							) : (
								<Button
									appearance="white"
									className="place-self-end"
									paddingX="minPhone:px-12 lg:px-32"
									grid="grid grid-cols-1"
									onClick={handleSaveAddress}
								>
									{t("another.save")}
								</Button>
							))}
					</div>
					<div className="grid gap-4">
						<P size="p3" type="light">
							{t("another.choosePaymentMethod")}
						</P>
						<div className="grid gap-4 grid-cols-maxTwo place-items-center">
							<RadioButton
								id="cash"
								name="cash"
								value="cash"
								label={t("another.cash")}
								checked={selectedValue === "cash"}
								onChange={handleRadioChange}
							/>
							{/* <RadioButton
								id="card"
								name="card"
								value="card"
								label="Via Card"
								checked={selectedValue === "card"}
								onChange={handleRadioChange}
							/> */}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="light">
							{t("another.deliveryTime")}
						</P>
						<div className="flex flex-wrap gap-4 place-items-center">
							{deliveryTime.map((time, index) => (
								<RadioButton
									key={index}
									id={`deliveryTime-${index}`}
									name="deliveryTime"
									value={time}
									label={time}
									checked={selectedDeliveryTime === time}
									onChange={handleDeliveryTimeChange}
								/>
							))}
						</div>
					</div>

					<div className="grid grid-cols-1 gap-4">
						<Textarea
							value={orderNotes}
							name="notes"
							onChange={handleOrderNotesChange}
							placeholder={t("placeholders.orderNote")}
							withlabel={true}
							label={t("another.orderNote")}
							rows={5}
						/>
						<div className="grid items-center gap-2 grid-cols-[auto_1fr]">
							<InfoIcon className="" />
							<P
								size="p3"
								type="light"
								className="flex-shrink break-words text-grayTwo"
							>
								{t("another.deliveryPrice")}
							</P>
						</div>
					</div>
				</div>
				<div className="xl:pt-10 TwoXl:pl-8 TwoXl:pr-32 lg:px-6 xl:border-l bg-bgColor xl:border-dividerColor">
					<div className="pb-6 border-b border-dividerColor">
						<CartBody checkoutPage={true} />
					</div>
					<div className="grid py-6 grid-cols-maxTwo place-content-between">
						<Htag tag="h3" type="regular">
							{t("another.total")}
						</Htag>
						<Htag tag="h3" type="regular">
							{totalAmount.toFixed(2)} {serviceConfig.currencySign}
						</Htag>
					</div>
					{loading ? (
						<Spinner inModal={true} />
					) : (
						<Button appearance="white" width="full" onClick={handleSubmitOrder}>
							{t("another.order")}
						</Button>
					)}
				</div>
			</div>
			<NotificationModal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				title={t("titles.checkout")}
				icon={<SuccessIcon />}
			/>
		</div>
	);
};

export default CheckoutPage;
