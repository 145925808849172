import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import authReducer from "../../redux/auth/authSlice";
import sliderReducer from "../../redux/slider/sliderSlice";
import companyReducer from "../../redux/company/companySlice";
import categoriesReducer from "../../redux/category/categorySlice";
import newArrivalsReducer from "../../redux/products/newArrivesSlice";
import salesReducer from "../../redux/products/salesSlice";
import bestSellingReducer from "../../redux/products/bestSellingSlice";
import searchReducer from "../../redux/products/searchSlice";
import cartReducer from "../../redux/cart/cartSlice";

const store = configureStore({
	reducer: {
		auth: authReducer,
		slider: sliderReducer,
		company: companyReducer,
		category: categoriesReducer,
		newArrivals: newArrivalsReducer,
		sales: salesReducer,
		bestSelling: bestSellingReducer,
		search: searchReducer,
		cart: cartReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;

// Add this line to define a custom dispatch type that understands thunks
export type AppDispatch = typeof store.dispatch;

export default store;
