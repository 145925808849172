import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSales } from "./salesAsyncThunks";

// Define a type for your state
type SalesState = {
	sales: any[]; // Replace 'any' with a more specific type if possible
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: SalesState = {
	sales: [],
	status: "idle",
	error: null,
};

const salesSlice = createSlice({
	name: "sales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSales.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchSales.fulfilled, (state, action: PayloadAction<any[]>) => {
				// Replace 'any[]' with a more specific type if possible
				state.status = "succeeded";
				state.sales = action.payload;
			})
			.addCase(fetchSales.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message ?? null;
			});
	},
});

export default salesSlice.reducer;
