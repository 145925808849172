import ProductCard from "components/ProductCard/ProductCard";
import getTranslation from "utils/getTranslation";

const renderProductCard = (product: any, isSaleProduct: boolean = false) => {
	let displayColor = product.colors[0];

	if (isSaleProduct) {
		// Find the first color with a discounted size
		displayColor = product.colors.find(
			(color: any) =>
				color.clothingSizes.some((size: any) => size.discounted) ||
				color.shoesSizes.some((size: any) => size.discounted)
		);

		// If no discounted color is found, return null
		if (!displayColor) return null;
	}

	const firstImage = displayColor.images[0] || ""; // Fallback image if not found
	const secondImage = displayColor.images[1] || ""; // Fallback image if not found
	const firstClothingSize = displayColor.clothingSizes[0]; // Assuming first size's details are used
	const firstShoesSize = displayColor.shoesSizes[0]; // Assuming first size's details are used

	// Assuming first size's price, discounted status, and discount percentage
	const price = firstClothingSize?.price || firstShoesSize?.price;
	const isDiscounted =
		firstClothingSize?.discounted || firstShoesSize?.discounted;
	const discountPercentage =
		firstClothingSize?.discount_percentage !== null &&
		firstClothingSize?.discount_percentage !== undefined
			? firstClothingSize?.discount_percentage
			: firstShoesSize?.discount_percentage !== null &&
			  firstShoesSize?.discount_percentage !== undefined
			? firstShoesSize?.discount_percentage
			: null;

	return (
		<ProductCard
			key={product._id}
			imgSrc={[firstImage, secondImage]}
			name={getTranslation(product.name)}
			price={price}
			discount={isDiscounted}
			discountPercentage={discountPercentage}
			id={product._id}
			item={product}
		/>
	);
};

export default renderProductCard;
