import React, { useEffect, useLayoutEffect, useState } from "react";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { ReactComponent as HamburgerIcon } from "common/assets/icons/Hamburger.svg";
import { ReactComponent as CloseIcon } from "common/assets/icons/cancel.svg";
import { ReactComponent as BackIcon } from "common/assets/icons/nav-arrow-left.svg";
import { motion } from "framer-motion";
import { routeConstants } from "navigation";
import { Link, useNavigate } from "react-router-dom";
import ManagePanel from "common/ManagePanel/ManagePanel";
import CartBody from "components/Cart/CartBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "components/App/store";
import { fetchAllCategories } from "redux/category/categoryAsyncThunk";
import { SearchInput } from "common/Search/Search";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const MobileNavbar: React.FC = (): JSX.Element => {
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const cartItems = useSelector((state: RootState) => state.cart.items);

	const [menuOpen, setMenuOpen] = useState(false);
	const [currentView, setCurrentView] = useState<
		"category" | "subcategory" | "subsubcategory"
	>("category");
	const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
	const [selectedSubcategory, setSelectedSubcategory] = useState<any>(null);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const handleCategoryClick = (category: any) => {
		setSelectedCategory(category);
		if (!category.subCategories || category.subCategories.length === 0) {
			handleNavigateCategoryClick(category._id); // Navigate to filter page if no subcategories
		} else {
			setCurrentView("subcategory");
		}
	};

	const toggleSearch = () => {
		setShowSearch(!showSearch);
	};

	const navigate = useNavigate();
	const { i18n, t } = useTranslation();

	const dispatch = useDispatch<AppDispatch>();
	const categories = useSelector(
		(state: RootState) => state.category.categories
	);
	const status = useSelector((state: RootState) => state.category.status);

	useEffect(() => {
		if (status !== "succeeded" && !categories.length) {
			dispatch(fetchAllCategories());
		}
	}, [status, categories, dispatch]);

	const handleSubcategoryClick = (subcategory: any) => {
		setSelectedSubcategory(subcategory);
		if (
			!subcategory.subSubCategories ||
			subcategory.subSubCategories.length === 0
		) {
			handleNavigateCategoryClick(subcategory._id); // Navigate to filter page if no subsubcategories
		} else {
			setCurrentView("subsubcategory");
		}
	};
	const resetMenu = () => {
		setCurrentView("category");
		setSelectedCategory(null);
		setSelectedSubcategory(null);
	};

	const handleBackClick = () => {
		if (currentView === "subsubcategory") {
			setCurrentView("subcategory");
			setSelectedSubcategory(null);
		} else if (currentView === "subcategory") {
			resetMenu();
		}
	};

	const menuAnimation = {
		hidden: { opacity: 0, x: "100%" }, // Starts from the right
		visible: { opacity: 1, x: "0%" }, // Moves to the left (visible)
		exit: { opacity: 0, x: "100%" }, // Exits to the right
	};

	// from left side animation
	// const menuAnimation = {
	// 	hidden: { opacity: 0, x: "-100%" }, // Starts from the left
	// 	visible: { opacity: 1, x: "0%" }, // Moves to the right (visible)
	// 	exit: { opacity: 0, x: "-100%" }, // Exits to the left
	// };

	const changeLanguage = (lng: any) => {
		i18n.changeLanguage(lng).then(() => {});
	};

	const slideAnimation = {
		hidden: { x: "100%", opacity: 0 },
		visible: { x: 0, opacity: 1, transition: { type: "tween", duration: 0.4 } },
		exit: {
			x: "-100%",
			opacity: 0,
			transition: { type: "tween", duration: 0.4 },
		},
	};

	const transition = { duration: 0.3, ease: "easeInOut" };
	const iconAnimation = {
		rotate: menuOpen ? 180 : 0,
	};

	console.log("HEHHEE", selectedSubcategory);
	useLayoutEffect(() => {
		if (menuOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [menuOpen]);

	const handleCheckout = () => {
		navigate(routeConstants.checkout.route);
		setIsCartOpen(false);
		setMenuOpen(false);
	};

	const handleCartClick = () => {
		setIsCartOpen((prevState) => !prevState);
	};

	const navigateAndCloseMenu = (route: string) => {
		navigate(route);
		setMenuOpen(false);
	};

	const handleNavigateCategoryClick = (categoryId: any, categoryType?: any) => {
		navigate(
			`${routeConstants.filter.route}/category?categoryId=${categoryId}&categoryType=${categoryType}`
		);
		setMenuOpen(false); // Close the menu on navigation
		resetMenu(); // Reset the menu state
	};

	const toggleMenu = () => {
		if (showSearch) {
			setShowSearch(false);
		} else {
			setMenuOpen(!menuOpen);
		}
	};

	const renderView = () => {
		switch (currentView) {
			case "category":
				return (
					<motion.div
						key="categories"
						variants={slideAnimation}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={transition}
						className="grid w-full gap-4 grid-rows-maxThree"
					>
						{categories.map((category: any) => (
							<P
								key={category._id}
								size="p2"
								type="regular"
								onClick={() => handleCategoryClick(category)}
							>
								{getTranslation(category.name)}
							</P>
						))}
					</motion.div>
				);

			case "subcategory":
				return (
					<motion.div
						key="subcategories"
						variants={slideAnimation}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={transition}
						className="grid w-full gap-4"
					>
						<div
							className="grid gap-2 grid-cols-maxTwo place-items-center"
							onClick={handleBackClick}
						>
							<BackIcon className="w-4 h-4 cursor-pointer" />
							<P size="t1" type="light">
								{getTranslation(selectedCategory.name)}
							</P>
						</div>
						{selectedCategory.subCategories.map((subcat: any) => (
							<>
								<P
									key={subcat._id}
									size="p2"
									type="light"
									onClick={() => handleSubcategoryClick(subcat)}
								>
									{getTranslation(subcat.name)}
								</P>
							</>
						))}
						<P
							size="p2"
							type="light"
							onClick={() =>
								handleNavigateCategoryClick(selectedCategory._id, "newArrivals")
							}
						>
							{t("another.newArrives")}
						</P>
						<P
							size="p2"
							type="light"
							className="text-redColor"
							onClick={() =>
								handleNavigateCategoryClick(selectedCategory._id, "newArrivals")
							}
						>
							{t("another.sales")}
						</P>
					</motion.div>
				);

			case "subsubcategory":
				return (
					<motion.div
						key="subsubcategories"
						variants={slideAnimation}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={transition}
						className="grid w-full gap-4"
					>
						<div
							className="grid gap-2 grid-cols-maxTwo place-items-center"
							onClick={handleBackClick}
						>
							<BackIcon className="w-4 h-4 cursor-pointer" />
							<P size="t1" type="light">
								{getTranslation(selectedSubcategory.name)}
							</P>
						</div>
						{selectedSubcategory.subSubCategories.map((subsubcat: any) => (
							<P
								key={subsubcat._id}
								size="p2"
								type="light"
								onClick={() => handleNavigateCategoryClick(subsubcat._id)}
							>
								{getTranslation(subsubcat.name)}
							</P>
						))}
					</motion.div>
				);

			default:
				return null;
		}
	};

	return (
		<div
			className={`fixed top-0 left-0 z-50 w-full bg-white ${
				menuOpen && "h-full"
			}`}
		>
			<div className="flex items-center justify-between px-5 border-b py-7 border-dividerColor">
				<Link to={routeConstants.root.route}>
					<Logo className="h-6" />
				</Link>
				<motion.div animate={iconAnimation}>
					{menuOpen ? (
						<CloseIcon onClick={toggleMenu} />
					) : (
						<HamburgerIcon onClick={() => setMenuOpen(!menuOpen)} />
					)}
				</motion.div>
			</div>
			{menuOpen && (
				<motion.div
					initial="hidden"
					animate="visible"
					exit="exit"
					variants={menuAnimation}
					transition={{ type: "tween", duration: 0.3 }}
					className={`grid grid-cols-1 gap-4 ${
						showSearch ? "px-0 pt-0 pb-0" : "px-5 pt-6 pb-6"
					} grid-rows-maxTwo place-content-between h-[90%]`}
				>
					{showSearch ? (
						<SearchInput
							placeholder={t("placeholders.search")}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							onSearchClose={() => {
								setShowSearch(false);
								setMenuOpen(!menuOpen);
							}}
						/>
					) : (
						<>
							<div className="grid w-full">{renderView()}</div>
							<div className="grid gap-4">
								<div className="grid gap-4 py-4 border-y border-dividerColor">
									<P size="p3" type="light" onClick={toggleSearch}>
										{t("another.search")}
									</P>

									<P
										size="p3"
										type="light"
										onClick={() =>
											navigateAndCloseMenu(routeConstants.account.route)
										}
									>
										{t("another.account")}
									</P>

									{isLoggedIn && (
										<P
											size="p3"
											type="light"
											onClick={() =>
												navigateAndCloseMenu(routeConstants.favourite.route)
											}
										>
											{t("another.favourites")}
										</P>
									)}

									<div
										className="grid gap-1 grid-cols-maxTwo place-items-center"
										onClick={handleCartClick}
									>
										<P size="p3" type="light">
											{t("another.myBag")}
										</P>
										<P size="p3" type="light" className="h-max">
											({cartItems.length})
										</P>
									</div>

									<ManagePanel
										isOpen={isCartOpen}
										onClose={() => setIsCartOpen(false)}
										onCheckout={handleCheckout}
										label={t("another.myBag")}
										cart={true}
									>
										<CartBody />
									</ManagePanel>
								</div>
								<div className="grid grid-flow-col-dense gap-2 auto-cols-max place-items-center">
									<P
										size="p2"
										type={i18n.language === "en" ? "regular" : "light"}
										onClick={() => changeLanguage("en")}
									>
										EN
									</P>
									<div className="self-center w-px h-5 bg-mainColor"></div>
									<P
										size="p2"
										type={i18n.language === "ru" ? "regular" : "light"}
										onClick={() => changeLanguage("ru")}
									>
										RU
									</P>
									<div className="self-center w-px h-5 bg-mainColor"></div>
									<P
										size="p2"
										type={i18n.language === "tk" ? "regular" : "light"}
										onClick={() => changeLanguage("tk")}
									>
										TM
									</P>
								</div>
							</div>
						</>
					)}
				</motion.div>
			)}
		</div>
	);
};

export default MobileNavbar;
