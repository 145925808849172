// FilterPage.tsx
import { RootState } from "components/App/store";
import ProductCard from "components/ProductCard/ProductCard";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import renderProductCard from "./renderProductCard";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { categoryService } from "services/categoryService";
import { ReactComponent as EmptyProductIcon } from "common/assets/icons/no_found.svg";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const FilterPage: React.FC = () => {
	const { category } = useParams<{
		category: string;
	}>();
	const query = useQuery();
	const { t } = useTranslation();
	const categoryId = query.get("categoryId");
	const categoryType = query.get("categoryType");
	const dispatch = useDispatch();
	const [categoryProducts, setCategoryProducts] = useState([]);
	const [categoryData, setCategoryData] = useState<any>();
	const products = useSelector((state: RootState) => {
		switch (category) {
			case "sales":
				return state.sales.sales;
			case "bestSelling":
				return state.bestSelling.bestSelling;
			case "newArrivals":
				return state.newArrivals.newArrivals;
			case "search":
				return state.search.searchResults;
			default:
				return [];
		}
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to top on component mount or update
	}, [categoryId, categoryType]); // Dependencies that trigger scroll to top

	useEffect(() => {
		const fetchCategoryProducts = async () => {
			if (categoryId) {
				try {
					setLoading(true);
					let response;
					switch (categoryType) {
						case "newArrivals":
							response = await categoryService.getCategoryNewArrivals(
								categoryId
							);
							break;
						case "sales":
							response = await categoryService.getCategorySales(categoryId);
							break;
						default:
							response = await categoryService.getCategoryProducts(categoryId);
					}
					setCategoryProducts(response.data.products);
					setCategoryData(response.data.category);
				} catch (error) {
					console.error("Error fetching category products:", error);
					toast.error(t("messages.productsError"));
				} finally {
					setLoading(false);
				}
			}
		};

		fetchCategoryProducts();
	}, [categoryId, categoryType]);

	const renderedProducts = categoryId ? categoryProducts : products;

	return (
		<>
			{loading && <Spinner />}
			<div className="grid minPhone:gap-6 minPhone:px-4 minPhone:pt-28 minPhone:pb-6 xl:gap-10 xl:px-32 xl:pt-32 xl:pb-20 min-h-[95vh]">
				{renderedProducts && renderedProducts.length > 0 ? (
					<>
						<Htag tag="h1" type="regular" className="m-auto">
							{category === "search"
								? t("another.searchResults")
								: categoryType === undefined || categoryType === "undefined"
								? getTranslation(categoryData?.name)
								: `${getTranslation(categoryData?.name)} ${
										categoryType === "newArrivals"
											? t("another.newArrivesFilter")
											: categoryType === "sales"
											? t("another.salesFilter")
											: getTranslation(categoryData?.name)
								  }`}
						</Htag>
						<div className="grid grid-cols-maxTwo place-items-center place-content-between">
							<P size="p2" type="regular">
								{renderedProducts.length} {t("another.products")}
							</P>
							{/* <P size="p2" type="regular">
				Filter
			</P> */}
						</div>

						<div className="grid minPhone:gap-5 xl:gap-10 md:grid-cols-maxThree TwoXl:grid-cols-maxThree ThreeXl:grid-cols-maxFour FourXl:grid-cols-maxFive FourXl:place-content-center minPhone:grid-cols-2">
							{renderedProducts.map((product: any) =>
								renderProductCard(product)
							)}
						</div>
					</>
				) : (
					<div className="grid grid-flow-row gap-4 place-items-center lg:py-56 auto-rows-max place-content-center">
						<EmptyProductIcon />
						<Htag tag="h3" type="regular">
							{t("another.noSearchResult")}
						</Htag>
					</div>
				)}
			</div>
		</>
	);
};

export default FilterPage;
