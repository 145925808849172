import axios from "axios";
import { serviceConfig } from "configs";

const createOrder = async (orderData: any) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/orders`,
			orderData
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred while creating the order.");
	}
};

const getOrdersByUser = async (userId: any, page = 1, limit = 100) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/orders/user/${userId}?page=${page}&limit=${limit}`
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred while fetching orders.");
	}
};

const updateUserOrder = async (userId: any, orderId: any, updateData: any) => {
	try {
		const response = await axios.put(
			`${serviceConfig.apiUrl}/orders/user/${userId}/order/${orderId}`,
			updateData
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred while updating the order.");
	}
};

const fetchOrderById = async (userId: any, orderId: any) => {
	console.log("Fetching order with ID:", orderId, "for user:", userId);
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/orders/user/${userId}/${orderId}`
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			throw new Error(
				error.response.data.message || "Error fetching the order."
			);
		}
		throw new Error("An unexpected error occurred while fetching the order.");
	}
};

export const orderService = {
	createOrder,
	getOrdersByUser,
	updateUserOrder,
	fetchOrderById,
};
