import React, { useEffect, useState } from "react";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import OTPModal from "components/OTPModal/OTPModal";
import { Htag } from "common/Htag/Htag";
import loginImage from "common/assets/images/login_image.png";
import { P } from "common/P/P";
import { useDispatch } from "react-redux";
import { AppDispatch } from "components/App/store";
import { authService } from "services/authService";
import { setUser } from "redux/auth/authSlice";
import { routeConstants } from "navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";

const ForgetPasswordPage: React.FC = () => {
	const [email, setEmail] = useState("");
	const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const dispatch = useDispatch<AppDispatch>();

	const handleSend = async () => {
		setIsLoading(true);
		try {
			await authService.forgetPasswordInit(email);
			setIsOtpModalOpen(true);
			toast.success(t("messages.otpSuccessSend"));
		} catch (error) {
			console.error("Failed to initialize password reset:", error);
			toast.error(t("messages.otpErrorSend"));
			// handle error (e.g. set an error state to display an error message)
		} finally {
			setIsLoading(false);
		}
	};

	const handleForgetPasswordFinal = async (otp: string) => {
		setIsLoading(true);
		try {
			const response = await authService.forgetPasswordFinal(email, otp);
			dispatch(setUser(response.user));
			navigate(routeConstants.root.route);
			toast.success(t("messages.resetSuccess"));
		} catch (error) {
			console.error("Failed to finalize password reset:", error);
			toast.error(t("messages.resetError"));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="grid minPhone:grid-cols-1 xl:grid-cols-2">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-10 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-48 minPhone:pb-28 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0">
				<div className="grid gap-2">
					<Htag tag="h1" type="regular">
						{t("another.resetPassword")}
					</Htag>
					<P size="p3" type="light">
						{t("another.resetPasswordMessage")}
					</P>
				</div>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="email"
						name="email"
						label={t("another.email")}
						placeholder={t("another.email")}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					{isLoading ? (
						<Spinner inModal={true} />
					) : (
						<Button appearance="white" onClick={handleSend} width="full">
							{t("another.send")}
						</Button>
					)}
					{isOtpModalOpen && (
						<OTPModal
							onClose={() => setIsOtpModalOpen(false)}
							isOpen={isOtpModalOpen}
							onConfirm={handleForgetPasswordFinal}
						/>
					)}
				</div>
			</div>

			<img
				src={loginImage}
				alt="login_image"
				className="object-cover w-full minPhone:hidden xl:block"
			/>
		</div>
	);
};

export default ForgetPasswordPage;
