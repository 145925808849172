import axios from "axios";
import { serviceConfig } from "configs";

const getBestSellingProducts = async () => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/products/bestselling`
		);
		return response.data.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const getSales = async () => {
	try {
		const response = await axios.get(`${serviceConfig.apiUrl}/products/sales`);
		return response.data.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const getNewArrivals = async () => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/products/newarrivals`
		);
		return response.data.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const getProductById = async (id: any) => {
	try {
		const response = await axios.get(`${serviceConfig.apiUrl}/products/${id}`);
		return response.data.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const searchProducts = async (query: any) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/products/search`,
			{
				params: { q: query },
			}
		);
		return response.data;
	} catch (error) {
		// handle error appropriately
		console.error("Error searching products:", error);
		throw error;
	}
};

export const productService = {
	getBestSellingProducts,
	getSales,
	getNewArrivals,
	getProductById,
	searchProducts,
};
