import React, { useEffect, useState } from "react";
import { SearchInputProps } from "./Search.props";
import "./Search.css";
import { ReactComponent as SearchIcon } from "common/assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import { fetchSearchResults } from "redux/products/searchAsyncThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "components/App/store";

export const SearchInput: React.FC<SearchInputProps> = ({
	value,
	placeholder = "Search...",
	onChange,
	onSearchClose,
	className,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const handleSearch = async (event: any) => {
		if (event.key === "Enter" && searchQuery.trim()) {
			await dispatch(fetchSearchResults(searchQuery));
			navigate(`/filter/search?query=${encodeURIComponent(searchQuery)}`);
			setSearchQuery("");
			if (onSearchClose) {
				onSearchClose(); // Use this to close the search bar
			}
		}
	};
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return isMobile ? (
		<input
			type="text"
			value={searchQuery}
			onChange={(e) => setSearchQuery(e.target.value)}
			onKeyDown={handleSearch}
			className="w-full h-full py-4 pl-4 pr-4 text-xs font-light uppercase transition-all duration-300 border-b rounded-sm mobile-input placeholder-mainColor border-dividerColor text-mainColor"
			placeholder={placeholder}
		/>
	) : (
		<div className={`relative bg-white shadow-xs ${className}`}>
			<span className="absolute inset-y-0 left-0 grid pl-4 place-items-center">
				<SearchIcon className="stroke-passiveTextColor" />
			</span>
			<input
				type="text"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				onKeyDown={handleSearch}
				className="input pl-12 w-full h-full py-4 pr-4 lg:w-[668px] border placeholder-mainColor rounded-sm border-mainColor transition-all duration-300 text-mainColor font-light uppercase"
				placeholder={placeholder}
			/>
		</div>
	);
};
