import React from "react";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";

const Splash: React.FC = () => {
	return (
		<div className="grid w-screen h-screen place-content-center place-items-center">
			<Logo className="h-16" />
		</div>
	);
};

export default Splash;
