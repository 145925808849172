import React from "react";
import { HtagProps } from "./Htag.props";

export const Htag = ({
	tag,
	type,
	children,
	className,
	onClick,
}: HtagProps): JSX.Element => {
	switch (tag) {
		case "h1":
			return (
				<h1
					className={`minPhone:text-xl lg:text-3xl tracking-[-2%] cursor-default uppercase ${
						type === "light"
							? "font-light"
							: type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					onClick={onClick}
				>
					{children}
				</h1>
			);
		case "h2":
			return (
				<h2
					className={`minPhone:text-lg lg:text-2xl tracking-[-2%] cursor-default uppercase ${
						type === "light"
							? "font-light"
							: type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					onClick={onClick}
				>
					{children}
				</h2>
			);
		case "h3":
			return (
				<h3
					className={`minPhone:text-base lg:text-xl tracking-[-2%] cursor-default uppercase ${
						type === "light"
							? "font-light"
							: type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					onClick={onClick}
				>
					{children}
				</h3>
			);
		default:
			return <></>;
	}
};
