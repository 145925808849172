import axios from "axios";
import { serviceConfig } from "configs";

const getWishlistForUser = async (userId: any) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/wishlist/user/${userId}`
		);
		return response.data;
	} catch (error: any) {
		throw (
			error.response?.data || "An error occurred while fetching the wishlist"
		);
	}
};

const addToWishlist = async ({ userId, productId }: any) => {
	try {
		const response = await axios.post(`${serviceConfig.apiUrl}/wishlist`, {
			userId,
			productId,
		});
		return response.data;
	} catch (error: any) {
		throw (
			error.response?.data || "An error occurred while adding to the wishlist"
		);
	}
};

const removeFromWishlist = async (id: string) => {
	try {
		const response = await axios.delete(
			`${serviceConfig.apiUrl}/wishlist/${id}`
		);
		return response.data;
	} catch (error: any) {
		throw (
			error.response?.data ||
			"An error occurred while removing from the wishlist"
		);
	}
};

const clearUserWishlist = async (userId: any) => {
	try {
		const response = await axios.delete(
			`${serviceConfig.apiUrl}/wishlist/user/${userId}`
		);
		return response.data;
	} catch (error: any) {
		throw (
			error.response?.data || "An error occurred while clearing the wishlist"
		);
	}
};

export {
	getWishlistForUser,
	addToWishlist,
	removeFromWishlist,
	clearUserWishlist,
};
