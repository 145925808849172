import React from "react";
import Slider from "react-slick";
import { CarouselProps } from "./Carousel.props";
import "./Carousel.css";

const Carousel: React.FC<CarouselProps> = ({ children }) => {
	const settings = {
		dots: false,
		infinite: false,
		speed: 1000,
		slidesToShow: Math.min(4, React.Children.count(children)),
		slidesToScroll: 1,
		autoplay: false,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 320,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Slider {...settings} className="grid-slide-container">
			{children}
		</Slider>
	);
};

export default Carousel;
