import React from "react";
import { PaginationProps } from "./Pagination.props";
import { useTranslation } from "react-i18next";

const Pagination: React.FC<PaginationProps> = ({
	currentPage,
	totalPages,
	onPageChange,
}) => {
	// const MAX_VISIBLE_BUTTONS = 5;
	const START_END_PAGES = 3;
	const { t } = useTranslation();

	const getStartRange = () => {
		if (totalPages <= 5) return 1; // If 5 or fewer total pages, always start from 1
		if (currentPage <= START_END_PAGES) return 1;
		if (currentPage >= totalPages - START_END_PAGES + 1) return totalPages - 4;
		return currentPage - 2;
	};

	const getEndRange = () => {
		if (totalPages <= 5) return totalPages; // If 5 or fewer total pages, always end at totalPages
		if (currentPage <= START_END_PAGES) return 5;
		if (currentPage >= totalPages - START_END_PAGES + 1) return totalPages;
		return currentPage + 2;
	};

	const isPrevDisabled = currentPage === 1;
	const isNextDisabled = currentPage === totalPages;

	return (
		<div className="flex gap-1 place-self-end">
			<button
				onClick={() => onPageChange(currentPage - 1)}
				disabled={isPrevDisabled}
				className={`px-3 py-2 rounded-lg bg-buttonColor text-passiveTextColor ${
					isPrevDisabled && "cursor-not-allowed"
				}`}
			>
				{t("another.previous")}
			</button>
			{currentPage > START_END_PAGES && (
				<>
					<button
						onClick={() => onPageChange(1)}
						className="px-4 py-2 rounded-lg bg-buttonColor text-passiveTextColor"
					>
						1
					</button>
					<button
						className={`px-3 py-2 rounded-lg bg-buttonColor text-passiveTextColor cursor-default`}
					>
						...
					</button>
				</>
			)}
			{Array.from(
				{ length: getEndRange() - getStartRange() + 1 },
				(_, index) => index + getStartRange()
			).map((page) => (
				<button
					key={page}
					onClick={() => onPageChange(page)}
					className={`px-4 py-2 rounded-lg ${
						page === currentPage
							? "bg-mainColor text-white"
							: "bg-buttonColor text-passiveTextColor"
					}`}
				>
					{page}
				</button>
			))}
			{currentPage < totalPages - START_END_PAGES + 1 && (
				<>
					<button
						className={`px-3 py-2 rounded-lg bg-buttonColor text-passiveTextColor cursor-default`}
					>
						...
					</button>

					<button
						onClick={() => onPageChange(totalPages)}
						className="px-4 py-2 rounded-lg bg-buttonColor text-passiveTextColor"
					>
						{totalPages}
					</button>
				</>
			)}
			<button
				onClick={() => onPageChange(currentPage + 1)}
				disabled={isNextDisabled}
				className={`px-3 py-2 rounded-lg bg-buttonColor text-passiveTextColor ${
					isNextDisabled && "cursor-not-allowed"
				}`}
			>
				{t("another.next")}
			</button>
		</div>
	);
};

export default Pagination;
