import { createSlice } from "@reduxjs/toolkit";
import { fetchAllCompanies } from "./companyAsyncThunks";

type CompanyState = {
	companies: any[];
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: CompanyState = {
	companies: [],
	status: "idle",
	error: null,
};

const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllCompanies.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchAllCompanies.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.companies = action.payload.data;
			})
			.addCase(fetchAllCompanies.rejected, (state, action) => {
				state.status = "failed";
				// This check ensures that error message is always string or null
				state.error = action.error.message || null;
			});
	},
});

export default companySlice.reducer;
