import axios from "axios";
import { serviceConfig } from "configs";

const registerInit = async (
	username: string,
	email: string,
	password: string
) => {
	try {
		const response = await axios.post(`${serviceConfig.apiUrl}/register-init`, {
			username,
			email,
			password,
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const registerFinal = async (email: string, otp: string) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/register-final`,
			{ email, otp }
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const login = async (email: string, password: string) => {
	try {
		const response = await axios.post(`${serviceConfig.apiUrl}/login`, {
			email,
			password,
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const resetPassword = async (
	email: string,
	oldPassword: string,
	newPassword: string
) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/reset-password`,
			{ email, oldPassword, newPassword }
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const forgetPasswordInit = async (email: string) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/forget-password-init`,
			{ email }
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const forgetPasswordFinal = async (email: string, otp: string) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/forget-password-final`,
			{ email, otp }
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

export const authService = {
	registerInit,
	registerFinal,
	login,
	resetPassword,
	forgetPasswordInit,
	forgetPasswordFinal,
};
