import React, { useEffect, useState } from "react";
import { Input } from "common/Input/Input";
import { P } from "common/P/P";
import { ReactComponent as EditIcon } from "common/assets/icons/edit-pencil.svg";
import { ReactComponent as InfoIcon } from "common/assets/icons/warning-circle.svg";
import { ReactComponent as NoAddressIcon } from "common/assets/empty_icons/no_address.svg";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import { motion, AnimatePresence } from "framer-motion";
import { Htag } from "common/Htag/Htag";
import Textarea from "common/Textarea/Textarea";
import { addressService } from "services/addressService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { updateAddresses } from "redux/auth/authSlice";
import { authService } from "services/authService";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";

type AddressData = {
	_id: string;
	address1: string;
};

const ProfilePage: React.FC = () => {
	const [isEditing, setIsEditing] = useState(false);
	const [passwords, setPasswords] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	});
	const [isAddingAddress, setIsAddingAddress] = useState(false);
	const [newAddress, setNewAddress] = useState("");
	const [passwordResetStatus, setPasswordResetStatus] = useState<string | null>(
		null
	);
	const [fetchingLoading, setFetchingLoading] = useState(false);
	const [submittingLoading, setSubmittingLoading] = useState(false);

	const { t } = useTranslation();

	const handleAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNewAddress(e.target.value);
	};
	const dispatch = useDispatch();
	const auth = useSelector((state: RootState) => state.auth.user);

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const userId = auth?._id || "";
	const userAddresses = auth?.addresses || [];
	const username = auth?.username || "";
	const email = auth?.email || "";
	const phoneNumber = auth?.phoneNumber || "";

	useEffect(() => {
		const fetchAddresses = async () => {
			setFetchingLoading(true);
			try {
				const response = await addressService.getAddressForUser(userId);
				if (response && response.data) {
					dispatch(updateAddresses(response.data));
				}
			} catch (error) {
				console.error("Failed to fetch addresses:", error);
				toast.error(t("messages.addressFetchError"));
			}
			setFetchingLoading(false);
		};
		fetchAddresses();
	}, [userId, dispatch]);

	const handleSaveAddress = async () => {
		if (newAddress && auth) {
			try {
				setSubmittingLoading(true);
				const addressData = {
					userId: userId,
					type: "default",
					address1: newAddress,
					address2: "",
					city: "Ashgabat",
					state: "",
					postalCode: "",
					country: "",
				};

				const response = await addressService.addAddressForUser(
					userId,
					addressData
				);

				// Update Redux state or local state as necessary
				const updatedAddresses = auth.addresses
					? [...auth.addresses, response.data]
					: [addressData];
				dispatch(updateAddresses(updatedAddresses));
				toast.success(t("messages.addressAddSuccess"));

				setNewAddress("");
				setIsAddingAddress(false);
			} catch (error) {
				console.error("Failed to add address:", error);
				toast.error(t("messages.addressAddError"));
			} finally {
				setSubmittingLoading(false);
			}
		}
	};

	const onDelete = async (addressId: string) => {
		try {
			setSubmittingLoading(true);
			await addressService.deleteAddress(addressId);
			if (auth && auth.addresses) {
				const updatedAddresses = auth.addresses.filter(
					(address: any) => address._id !== addressId
				);
				dispatch(updateAddresses(updatedAddresses));
				setSubmittingLoading(false);
				toast.success(t("messages.addressDeletedSuccess"));
			}
		} catch (error) {
			console.error("Failed to delete address:", error);
			toast.error(t("messages.addressDeletedError"));
		} finally {
			setSubmittingLoading(false);
		}
	};
	const handleCancelAddress = () => {
		setNewAddress("");
		setIsAddingAddress(false);
	};

	const handleResetPassword = async () => {
		if (isPasswordValid()) {
			try {
				setSubmittingLoading(true);
				const response = await authService.resetPassword(
					email,
					passwords.oldPassword,
					passwords.newPassword
				);
				setPasswordResetStatus(response.message);
				setPasswords({
					oldPassword: "",
					newPassword: "",
					confirmPassword: "",
				});
				setIsEditing(false);
				toast.success(t("messages.changePasswordSuccess"));
			} catch (error: any) {
				console.error("Failed to reset password:", error);
				toast.error(t("messages.changePasswordError"));
				setPasswordResetStatus(
					error.message || "An unexpected error occurred."
				);
			} finally {
				setSubmittingLoading(false);
			}
		} else {
			setPasswordResetStatus("Passwords do not match");
		}
	};

	const fadeIn = {
		hidden: { opacity: 0, height: 0 },
		visible: { opacity: 1, height: "auto", transition: { duration: 0.3 } },
	};

	const fadeOut = {
		exit: { opacity: 0, height: 0, transition: { duration: 0.3 } },
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setPasswords((prev) => ({ ...prev, [name]: value }));
	};

	const isPasswordValid = () => {
		return (
			passwords.newPassword &&
			passwords.newPassword === passwords.confirmPassword
		);
	};

	if (fetchingLoading) {
		return <Spinner />;
	}

	return (
		<div className="grid min-h-[50vh] minPhone:grid-cols-1 xl:grid-cols-2 minPhone:gap-4 xl:gap-10 minPhone:mt-6 xl:mt-10">
			<div className="grid grid-flow-row gap-10 bg-white rounded-sm minPhone:p-4 xl:p-8 auto-rows-max h-max">
				<Input
					appearance="default"
					minWidth={window.innerWidth < 1280 ? false : true}
					type="text"
					name="username"
					withlabel={true}
					label={t("another.username")}
					placeholder={t("another.username")}
					defaultValue={username}
					disabled={!isEditing}
				/>
				<Input
					appearance="default"
					minWidth={window.innerWidth < 1280 ? false : true}
					type="text"
					name="phone_number"
					withlabel={true}
					label={t("another.phoneNumber")}
					placeholder={t("another.phoneNumber")}
					defaultValue={phoneNumber}
					disabled
				/>
				<AnimatePresence>
					{isEditing && (
						<motion.div
							variants={fadeIn}
							initial="hidden"
							animate="visible"
							exit="exit"
							className="grid gap-10"
						>
							<Input
								appearance="default"
								minWidth={window.innerWidth < 1280 ? false : true}
								type="password"
								name="oldPassword"
								withlabel={true}
								label={t("another.oldPassword")}
								placeholder={t("another.oldPassword")}
								value={passwords.oldPassword}
								onChange={handleInputChange}
							/>
							<Input
								appearance="default"
								minWidth={window.innerWidth < 1280 ? false : true}
								type="password"
								name="newPassword"
								withlabel={true}
								label={t("another.newPassword")}
								placeholder={t("another.newPassword")}
								value={passwords.newPassword}
								onChange={handleInputChange}
							/>
							<div className="grid gap-4">
								<Input
									appearance="default"
									minWidth={window.innerWidth < 1280 ? false : true}
									type="password"
									name="confirmPassword"
									withlabel={true}
									label={t("another.confirmPassword")}
									placeholder={t("another.confirmPassword")}
									value={passwords.confirmPassword}
									onChange={handleInputChange}
								/>
								{passwords.newPassword &&
									passwords.confirmPassword &&
									!isPasswordValid() && (
										<div className="grid gap-2 grid-cols-maxTwo place-items-center">
											<InfoIcon className="w-5 h-5 stroke-redColor" />
											<P size="p3" type="light" className="text-redColor">
												{t("placeholders.incorrectPassword")}
											</P>
										</div>
									)}
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				{isEditing ? (
					<div className="grid grid-cols-2 gap-4 cursor-pointer place-items-center">
						<Button
							appearance="white"
							width="full"
							onClick={() => setIsEditing(!isEditing)}
						>
							{t("another.cancel")}
						</Button>
						{submittingLoading ? (
							<Spinner inModal={true} />
						) : (
							<Button
								appearance="black"
								width="full"
								onClick={handleResetPassword}
							>
								{t("another.save")}
							</Button>
						)}
					</div>
				) : (
					<div
						className="grid gap-1 cursor-pointer grid-cols-maxTwo place-items-center"
						onClick={() => setIsEditing(!isEditing)}
					>
						<EditIcon />
						<P size="p3" type="light" className="cursor-pointer">
							{t("another.edit")}
						</P>
					</div>
				)}
			</div>
			<div className="bg-white rounded-sm minPhone:p-4 xl:p-8 h-max">
				{!userAddresses.length ? (
					<>
						{isAddingAddress ? (
							<motion.div
								variants={fadeIn}
								initial="hidden"
								animate="visible"
								exit="exit"
								className="grid gap-6"
							>
								<Textarea
									label={t("another.addAddress")}
									value={newAddress}
									onChange={handleAddressChange}
									withlabel={true}
									placeholder={t("placeholders.enterYourAddress")}
									name="add_address"
								/>
								<div className="grid grid-cols-2 gap-4">
									<Button
										appearance="white"
										width="full"
										onClick={handleCancelAddress}
									>
										{t("another.cancel")}
									</Button>
									{submittingLoading ? (
										<Spinner inModal={true} />
									) : (
										<Button
											appearance="black"
											width="full"
											onClick={handleSaveAddress}
										>
											{t("another.save")}
										</Button>
									)}
								</div>
							</motion.div>
						) : (
							<div className="grid gap-4 place-items-center">
								<NoAddressIcon />
								<Htag
									tag="h2"
									type="light"
									className="minPhone:text-base xl:text-2xl"
								>
									{t("another.noAddress")}
								</Htag>
								<Button
									appearance="black"
									paddingX="minPhone:px-16 xl:px-32"
									icon={<PlusIcon className="stroke-white" />}
									onClick={() => setIsAddingAddress(true)}
								>
									{t("another.addAnAddress")}
								</Button>
							</div>
						)}
					</>
				) : (
					<div className="grid gap-6">
						{userAddresses.map((addressData: AddressData, idx) => (
							<div key={addressData._id}>
								<Input
									appearance="default"
									minWidth={window.innerWidth < 1280 ? false : true}
									type="text"
									name={`address1-${idx}`}
									withlabel={idx === 0}
									label={t("another.myAddresses")}
									placeholder=""
									defaultValue={addressData.address1}
									deletable={true}
									onDelete={() => onDelete(addressData._id)}
									disabled
								/>
							</div>
						))}

						<AnimatePresence>
							{isAddingAddress && (
								<motion.div
									variants={fadeIn}
									initial="hidden"
									animate="visible"
									exit="exit"
									className="grid gap-6"
								>
									<Textarea
										label={t("another.addAddress")}
										value={newAddress}
										onChange={handleAddressChange}
										withlabel={true}
										placeholder={t("placeholders.enterYourAddress")}
										name="add_address"
									/>
									<div className="grid grid-cols-2 gap-4">
										<Button
											appearance="white"
											width="full"
											onClick={handleCancelAddress}
										>
											{t("another.cancel")}
										</Button>
										{submittingLoading ? (
											<Spinner inModal={true} />
										) : (
											<Button
												appearance="black"
												width="full"
												onClick={handleSaveAddress}
											>
												{t("another.save")}
											</Button>
										)}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
						{!isAddingAddress && (
							<Button
								appearance="black"
								width="full"
								icon={<PlusIcon className="stroke-white" />}
								onClick={() => setIsAddingAddress(true)}
							>
								{t("another.addAnAddress")}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfilePage;
