import React from "react";
import { OrderStatusProps } from "./OrderStatus.props";
import { P } from "common/P/P";
import { useTranslation } from "react-i18next";

const OrderStatus: React.FC<OrderStatusProps> = ({ status, size = "t1" }) => {
	const { t } = useTranslation();
	switch (status) {
		case "Waiting":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 rounded-2xl text-waitingColor"
				>
					{t("another.waiting")}
				</P>
			);
		case "Preparing":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-preparingColor"
				>
					{t("another.preparing")}
				</P>
			);
		case "Delivered":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-deliveryColor"
				>
					{t("another.delivered")}
				</P>
			);
		case "Cancelled":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-cancelColor"
				>
					{t("another.cancelled")}
				</P>
			);
		case "Returned":
			return (
				<P
					size={size}
					type="regular"
					className="px-2 py-1 font-medium rounded-2xl text-returnColor"
				>
					{t("another.returned")}
				</P>
			);
		default:
			return null;
	}
};

export default OrderStatus;
