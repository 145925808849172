import { Htag } from "common/Htag/Htag";
import ProductCard from "components/ProductCard/ProductCard";
import React, { useEffect, useState } from "react";

import MainSlider from "components/MainSlider/MainSlider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "components/App/store";
import { fetchAllSliders } from "redux/slider/sliderAsyncThunks";
import { serviceConfig } from "configs";
import { fetchNewArrivals } from "redux/products/newArrivesAsyncThunks";
import { fetchSales } from "redux/products/salesAsyncThunks";
import { fetchBestSellingProducts } from "redux/products/bestSellingAsyncThunks";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { routeConstants } from "navigation";
import Splash from "modules/loaders/Splash/Splash";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const MainPage: React.FC = (): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const sliderImages = useSelector((state: RootState) => state.slider.images);
	const auth = useSelector((state: RootState) => state.auth.user);
	const sliderStatus = useSelector((state: RootState) => state.slider.status);
	const newArrivals = useSelector(
		(state: RootState) => state.newArrivals.newArrivals
	);
	const sales = useSelector((state: RootState) => state.sales.sales);
	const bestSelling = useSelector(
		(state: RootState) => state.bestSelling.bestSelling
	);
	const navigate = useNavigate();
	const { t } = useTranslation();

	// Function to handle navigation
	const handleNavigate = (category: string) => {
		navigate(`${routeConstants.filter.route}/${category}`);
	};

	const [loading, setLoading] = useState(true);

	// Motion variants for the underline animation
	const underlineVariants = {
		initial: { width: 0 },
		animate: { width: "100%" },
	};

	const [displayCount, setDisplayCount] = useState<number>(0);

	// Function to update display count based on window size
	const updateDisplayCount = () => {
		setDisplayCount(window.innerWidth <= 768 ? 3 : 6);
	};

	useEffect(() => {
		updateDisplayCount(); // Set initial display count on mount
		const handleResize = () => {
			updateDisplayCount(); // Update display count on window resize
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true); // Set loading to true before fetching data
			await dispatch(fetchNewArrivals());
			await dispatch(fetchSales());
			await dispatch(fetchBestSellingProducts());
			if (sliderStatus === "idle") {
				await dispatch(fetchAllSliders());
			}
			setLoading(false); // Set loading to false after fetching all data
		};

		fetchData();
	}, [dispatch, sliderStatus]);

	const renderProductCard = (product: any, isSaleProduct: boolean = false) => {
		let displayColor = product.colors[0];

		if (isSaleProduct) {
			// Find the first color with a discounted size
			displayColor = product.colors.find(
				(color: any) =>
					color.clothingSizes.some((size: any) => size.discounted) ||
					color.shoesSizes.some((size: any) => size.discounted)
			);

			// If no discounted color is found, return null
			if (!displayColor) return null;
		}

		const firstImage = displayColor.images[0] || ""; // Fallback image if not found
		const secondImage = displayColor.images[1] || ""; // Fallback image if not found
		const firstClothingSize = displayColor.clothingSizes[0]; // Assuming first size's details are used
		const firstShoesSize = displayColor.shoesSizes[0]; // Assuming first size's details are used

		// Assuming first size's price, discounted status, and discount percentage
		const price = firstClothingSize?.price || firstShoesSize?.price;
		const isDiscounted =
			firstClothingSize?.discounted || firstShoesSize?.discounted;
		const discountPercentage =
			firstClothingSize?.discount_percentage ||
			firstShoesSize?.discount_percentage;

		return (
			<ProductCard
				key={product._id}
				imgSrc={[firstImage, secondImage]}
				name={getTranslation(product.name)}
				price={price}
				discount={isDiscounted}
				discountPercentage={discountPercentage}
				id={product._id}
				item={product}
			/>
		);
	};

	return (
		<>
			{loading ? (
				<Splash />
			) : (
				<>
					<MainSlider
						images={sliderImages.map(
							(slider) => `${serviceConfig.apiUrl}${slider.image}`
						)}
					/>
					<div className="grid minPhone:px-5 minPhone:pt-14 minPhone:pb-6 lg:px-16 xl:gap-20 TwoXl:px-32 lg:pb-20 xl:pt-28 minPhone:gap-14">
						{sales && sales.length > 0 && (
							<div className="grid gap-10 text-center">
								<motion.div
									initial="initial"
									whileHover="animate"
									className="grid m-auto place-content-center"
								>
									<Htag
										tag="h1"
										type="regular"
										className="cursor-pointer"
										onClick={() => handleNavigate("sales")}
									>
										{t("another.sales")}
									</Htag>
									<motion.div
										className="underline"
										style={{
											height: "2px",
											backgroundColor: "currentcolor",
										}}
										variants={underlineVariants}
										transition={{ duration: 0.3 }} // Customize as needed
									/>
								</motion.div>
								<div className="grid minPhone:gap-5 xl:gap-10 md:grid-cols-maxThree TwoXl:grid-cols-maxThree ThreeXl:grid-cols-maxFour FourXl:grid-cols-maxFive FourXl:place-content-center minPhone:grid-cols-2 minPhone:place-content-center xl:place-content-stretch">
									{sales
										.slice(0, displayCount)
										.map((product) => renderProductCard(product, true))}
								</div>
							</div>
						)}

						{newArrivals && newArrivals.length > 0 && (
							<div className="grid gap-10 text-center">
								<motion.div
									initial="initial"
									whileHover="animate"
									className="grid m-auto place-content-center"
								>
									<Htag
										tag="h1"
										type="regular"
										className="cursor-pointer"
										onClick={() => handleNavigate("newArrivals")}
									>
										{t("another.newArrives")}
									</Htag>
									<motion.div
										className="underline"
										style={{
											height: "2px",
											backgroundColor: "currentcolor",
										}}
										variants={underlineVariants}
										transition={{ duration: 0.3 }} // Customize as needed
									/>
								</motion.div>

								<div className="grid minPhone:gap-5 xl:gap-10 md:grid-cols-maxThree TwoXl:grid-cols-maxThree ThreeXl:grid-cols-maxFour FourXl:grid-cols-maxFive FourXl:place-content-center minPhone:grid-cols-2 minPhone:place-content-center xl:place-content-stretch ">
									{newArrivals
										.slice(0, displayCount)
										.map((product) => renderProductCard(product))}
								</div>
							</div>
						)}

						{bestSelling && bestSelling.length > 0 && (
							<div className="grid gap-10 text-center">
								<motion.div
									initial="initial"
									whileHover="animate"
									className="grid m-auto place-content-center"
								>
									<Htag
										tag="h1"
										type="regular"
										className="cursor-pointer"
										onClick={() => handleNavigate("bestSelling")}
									>
										{t("another.bestSellingIconics")}
									</Htag>
									<motion.div
										className="underline"
										style={{
											height: "2px",
											backgroundColor: "currentcolor",
										}}
										variants={underlineVariants}
										transition={{ duration: 0.3 }} // Customize as needed
									/>
								</motion.div>

								<div className="grid minPhone:gap-5 xl:gap-10 md:grid-cols-maxThree TwoXl:grid-cols-maxThree ThreeXl:grid-cols-maxFour FourXl:grid-cols-maxFive FourXl:place-content-center minPhone:grid-cols-2 minPhone:place-content-center xl:place-content-stretch ">
									{bestSelling
										.slice(0, displayCount)
										.map((product) => renderProductCard(product))}
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default MainPage;
