// components/CartRow.tsx

import React, { useEffect, useState } from "react";
import { CartRowProps } from "./Cart.props";
import Modal from "common/Modal/Modal";
import CounterButton from "common/CounterButton/CounterButton";
import { P } from "common/P/P";

import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { Htag } from "common/Htag/Htag";
import { AppDispatch, RootState } from "components/App/store";
import { useDispatch, useSelector } from "react-redux";
import {
	decreaseQuantity,
	increaseQuantity,
	removeItem,
} from "redux/cart/cartSlice";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";

const CartRow: React.FC<CartRowProps> = ({
	imgSrc,
	name,
	size,
	color,
	price,
	discount,
	discountPercentage,
	discountPrice,
	quantity,
	productId,
	product,
	checkoutPage,
	orderPage,
	selectedColorId,
	selectedSizeId,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [productQuantity, setProductQuantity] = useState(quantity);
	const cartItems = useSelector((state: RootState) => state.cart.items);
	console.log("CART ITEMS", cartItems);
	const { t } = useTranslation();

	const handleIncrease = () => {
		console.log(
			`Increasing Quantity for Product: ${productId}, Color: ${selectedColorId}, Size: ${selectedSizeId}`
		);
		dispatch(increaseQuantity({ productId, selectedColorId, selectedSizeId }));
	};

	const handleDecrease = () => {
		const sizeId = selectedSizeId;
		console.log(
			`Decreasing Quantity for Product: ${productId}, Color: ${selectedColorId}, Size: ${sizeId}`
		);
		if (productQuantity <= 1) {
			dispatch(removeItem({ productId, selectedColorId, selectedSizeId }));
		} else {
			dispatch(
				decreaseQuantity({ productId, selectedColorId, selectedSizeId })
			);
		}
	};

	const confirmDelete = () => {
		dispatch(removeItem({ productId, selectedColorId, selectedSizeId }));
	};

	useEffect(() => {
		const currentItem = cartItems.find(
			(item: any) =>
				item.product._id === productId &&
				item.selectedColorId._id === selectedColorId &&
				item.selectedSizeId._id === selectedSizeId
		);
		if (currentItem) {
			setProductQuantity(currentItem.quantity);
		}
	}, [cartItems, productId, selectedColorId, selectedSizeId]);

	return (
		<div
			className={`grid gap-4 grid-cols-maxFr ${
				orderPage && "shadow-cardShadow p-4 bg-white"
			}`}
		>
			<img
				src={`${serviceConfig.apiUrl}${imgSrc}`}
				alt={name}
				className="w-[96px] h-full object-cover"
			/>
			<div
				className={`grid  grid-rows-frMax ${
					discount && discountPercentage > 0 ? "gap-0" : "gap-4"
				}`}
			>
				<div className="grid gap-2 h-max">
					<div className="grid grid-cols-maxTwo place-content-between place-items-center">
						<P size="p3" type="regular">
							{name}
						</P>
						{!checkoutPage && !orderPage && (
							<CloseIcon onClick={confirmDelete} className="cursor-pointer" />
						)}
					</div>
					{orderPage ? (
						<>
							<P size="p3" type="light">
								{t("another.size")}: {size}
							</P>
							<P size="p3" type="light">
								{t("another.color")}: {color}
							</P>
						</>
					) : (
						<>
							<P size="t1" type="light">
								{t("another.size")}: {size}
							</P>
							<P size="t1" type="light">
								{t("another.color")}: {color}
							</P>
						</>
					)}
				</div>
				<div className="grid grid-cols-maxTwo place-items-center place-content-between">
					{checkoutPage ? (
						<P size="t1" type="light" className="place-self-end">
							{t("another.pieces")}: {productQuantity}
						</P>
					) : orderPage ? (
						<P size="p3" type="light">
							{t("another.pieces")}: {productQuantity}
						</P>
					) : (
						<CounterButton
							counter={productQuantity}
							onDecrease={handleDecrease}
							onIncrease={handleIncrease}
							onBelowLimit={confirmDelete}
							className="place-self-end"
						/>
					)}
					{orderPage ? (
						<div className="grid gap-2 grid-cols-maxThree place-items-center">
							{discount && (
								<span className="px-2 py-1 rounded-sm bg-mainColor">
									<P size="p2" type="light" className="text-white">
										{discountPercentage}%
									</P>
								</span>
							)}
							{discount && discountPercentage > 0 ? (
								<>
									<P
										size="p2"
										type="light"
										className="line-through text-grayTwo"
									>
										{price} {serviceConfig.currencySign}
									</P>

									<Htag tag="h3" type="regular">
										{discountPrice} {serviceConfig.currencySign}
									</Htag>
								</>
							) : (
								<Htag tag="h3" type="regular">
									{price} {serviceConfig.currencySign}
								</Htag>
							)}
						</div>
					) : discount && discountPercentage > 0 ? (
						<div className="grid gap-1">
							{discount && (
								<span className="px-2 py-1 rounded-sm bg-mainColor w-max place-self-end">
									<P size="p3" type="light" className="text-white">
										{discountPercentage}%
									</P>
								</span>
							)}
							<div className="grid gap-2 grid-cols-maxTwo place-items-center">
								<P size="p3" type="light" className="line-through text-grayTwo">
									{price} {serviceConfig.currencySign}
								</P>
								<P size="p2" type="regular">
									{discountPrice} {serviceConfig.currencySign}
								</P>
							</div>
						</div>
					) : (
						<P size="p2" type="regular">
							{price} {serviceConfig.currencySign}
						</P>
					)}
				</div>
			</div>
		</div>
	);
};

export default CartRow;
