import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchNewArrivals } from "./newArrivesAsyncThunks";

// Define a type for your state
type NewArrivalsState = {
	newArrivals: any[]; // Replace 'any' with a more specific type if possible
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: NewArrivalsState = {
	newArrivals: [],
	status: "idle",
	error: null,
};

const newArrivalsSlice = createSlice({
	name: "newArrivals",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNewArrivals.pending, (state) => {
				state.status = "loading";
			})
			.addCase(
				fetchNewArrivals.fulfilled,
				(state, action: PayloadAction<any[]>) => {
					// Replace 'any[]' with a more specific type if possible
					state.status = "succeeded";
					state.newArrivals = action.payload;
				}
			)
			.addCase(fetchNewArrivals.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message ?? null;
			});
	},
});

export default newArrivalsSlice.reducer;
