interface Translatable {
	en: string;
	ru: string;
	tk: string;
}

type Language = keyof Translatable;

const getTranslation = (translatable: Translatable | undefined): string => {
	const currentLang = (localStorage.getItem("i18nextLng") as Language) || "en";
	if (translatable) {
		return translatable[currentLang] || translatable.en;
	}
	return ""; // Fallback for undefined translatable objects
};

export default getTranslation;
