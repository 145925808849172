// contactService.js
import axios from "axios";
import { serviceConfig } from "configs";

const submitContactData = async (contactData: any) => {
	try {
		const response = await axios.post(
			`${serviceConfig.apiUrl}/contact`,
			contactData
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

export const contactService = {
	submitContactData,
};
