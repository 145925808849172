import React from "react";

import { ButtonProps } from "./Button.props";

export const Button = ({
	children,
	appearance,
	icon = "none",
	className = "",
	width = "max",
	paddingX = "px-16",
	paddingY = "py-3",
	center = true,
	grid = "grid grid-cols-maxTwo gap-2",
	wishlist,
	...props
}: ButtonProps): JSX.Element => {
	return (
		<button
			className={`${
				appearance === "black" && wishlist
					? "bg-mainColor text-white border border-mainColor"
					: appearance === "black"
					? "bg-mainColor text-white border border-mainColor hover:bg-buttonColor hover:text-mainColor"
					: appearance === "white"
					? "bg-buttonColor text-mainColor border border-mainColor hover:bg-mainColor hover:text-buttonColor"
					: appearance === "disabled"
					? "bg-buttonColor text-mainColor border border-mainColor cursor-not-allowed"
					: ""
			} 
			w-${width} transition-all duration-300 group
			${
				center
					? "place-content-center place-items-center"
					: "place-content-start place-items-center"
			}
				${paddingY} ${paddingX} rounded-sm shadow-xs ${
				icon && grid
			} ${className} uppercase text-base font-light`}
			{...props}
		>
			{icon !== "none" && <>{icon}</>}
			{children}
		</button>
	);
};
