import { Button } from "common/Button/Button";
import { Htag } from "common/Htag/Htag";
import OrderStatus from "common/OrderStatus/OrderStatus";
import { P } from "common/P/P";
import { RootState } from "components/App/store";
import CartBody from "components/Cart/CartBody";
import { serviceConfig } from "configs";
import Spinner from "modules/loaders/Spinner/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { orderService } from "services/orderService";

const OrderDetailsPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { t } = useTranslation();

	const [order, setOrder] = useState<any | null>(null);
	const [loading, setLoading] = useState(true);
	const userId = useSelector((state: RootState) => state.auth.user?._id);
	console.log("IDDD", id, userId);

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	useEffect(() => {
		console.log("Inside useEffect", id, userId);
		const fetchOrder = async () => {
			if (!userId || !id) return; // Exit if no IDs are present

			try {
				const response = await orderService.fetchOrderById(userId, id);
				if (response.data) {
					setOrder(response.data);
				}
			} catch (error) {
				console.error("Error fetching order:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchOrder();
	}, [userId, id]); // Dependencies on `userId` and `orderId`

	const handleCancelOrder = async () => {
		if (order.orderStatus === "Waiting") {
			try {
				await orderService.updateUserOrder(order.userId, order._id, {
					orderStatus: "Cancelled",
				});
				window.location.reload(); // Reload the page to reflect the changes
			} catch (error) {
				console.error("Error cancelling order:", error);
			}
		}
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className="grid minPhone:gap-4 xl:gap-8">
			<div className="grid py-4 minPhone:px-4 lg:px-8 bg-mainColor h-max grid-cols-maxTwo place-items-center place-content-between">
				<div className="grid gap-2">
					<Htag tag="h2" type="regular" className="text-white">
						{t("another.order")} #{order ? order._id.slice(0, 6) : "Loading..."}
					</Htag>
					<P size="p2" type="light" className="text-white">
						{order && order.orderDate
							? new Date(order.orderDate)
									.toLocaleDateString("en-GB", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
									})
									.replace(/\//g, ".")
							: "Loading..."}
					</P>
				</div>
				<OrderStatus status={order.orderStatus} size="p3" />
			</div>
			<div className="grid minPhone:grid-cols-1 xl:grid-cols-[60%_1fr] minPhone:gap-6 xl:gap-10">
				<CartBody
					checkoutPage={window.innerWidth <= 1280 && true}
					orderPage={window.innerWidth >= 1280 && true}
					orderProducts={order.products}
					className={"md:px-6 !h-max"}
				/>
				<div className="grid gap-6 h-max shadow-cardShadow">
					<div className="p-6 bg-white h-max">
						<div className="grid gap-4 pb-4 border-b border-dividerColor">
							<div className="grid grid-cols-maxTwo place-items-center place-content-between">
								<P size="p2" type="light">
									{t("another.total")}
								</P>
								<P size="p1" type="regular">
									{order.orderSummary.subtotal} {serviceConfig.currencySign}
								</P>
							</div>
							<div className="grid grid-cols-maxTwo place-items-center place-content-between">
								<P size="p2" type="light">
									{t("another.delivery")}
								</P>
								<P size="p1" type="regular">
									{order.orderSummary.shippingCharges}{" "}
									{serviceConfig.currencySign}
								</P>
							</div>
						</div>
						<div className="grid pt-4 grid-cols-maxTwo place-items-center place-content-between">
							<P size="p2" type="light">
								{t("another.orderTotal")}
							</P>
							<P size="p1" type="regular">
								{order.orderSummary.total} {serviceConfig.currencySign}
							</P>
						</div>
					</div>
					<Button
						appearance={order.orderStatus === "Waiting" ? "black" : "white"}
						className={
							order.orderStatus === "Waiting"
								? "cursor-pointer"
								: "cursor-not-allowed"
						}
						width="full"
						onClick={handleCancelOrder}
					>
						{t("another.cancelOrder")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default OrderDetailsPage;
