import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { WishlistButtonProps } from "./WishlistButton.props";
import { ReactComponent as WishlistIcon } from "common/assets/icons/heart.svg";
import { addToWishlist, removeFromWishlist } from "services/wishlistService";
import { AppDispatch, RootState } from "components/App/store";
import { useDispatch, useSelector } from "react-redux";
import { addWishlistItem, removeWishlistItem } from "redux/auth/authSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WishlistButton: React.FC<WishlistButtonProps> = ({
	isActive: initialActive = false,
	onToggle,
	paddingY = "minPhone:py-2 xl:py-4",
	paddingX = "minPhone:px-2 xl:px-4",
	productId,
	wishId,
	wished,
	product,
}): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const userId = useSelector((state: RootState) => state.auth.user?._id);
	const userWishlist = useSelector(
		(state: RootState) => state.auth.user?.wishlist
	);
	const [isActive, setIsActive] = useState<boolean>(initialActive);
	console.log("ACTIVE", product);

	useEffect(() => {
		setIsActive(wished);
	}, [wished]);

	useEffect(() => {
		// Check if the product ID is present in the user's wishlist
		const isProductInWishlist =
			userWishlist?.some((item) => item.productId._id === productId) ?? false;
		setIsActive(isProductInWishlist);
	}, [userWishlist, productId]);

	const handleToggle = async () => {
		try {
			if (!isActive) {
				const response = await addToWishlist({
					userId,
					productId: product._id,
				});
				dispatch(addWishlistItem(response.data));
				toast.success(t("another.addedToWishlist")); // Success message for adding to wishlist
			} else {
				const wishlistItemId = userWishlist?.find(
					(item) => item.productId._id === productId
				)?._id;
				if (wishlistItemId) {
					await removeFromWishlist(wishlistItemId);
					dispatch(removeWishlistItem(wishlistItemId));
					toast.success(t("another.removedFromWishlist")); // Success message for removing from wishlist
				}
			}
			setIsActive(!isActive);
			onToggle && onToggle(!isActive);
		} catch (error) {
			console.error("Error toggling wishlist item:", error);
			setIsActive(isActive); // Revert isActive in case of error
			toast.error(t("another.wishlistError")); // Error message for wishlist operation
		}
	};

	return (
		<Button
			appearance={isActive ? "black" : "white"}
			icon={
				<WishlistIcon
					className={
						isActive
							? "fill-favouriteColor stroke-favouriteColor"
							: "group-hover:stroke-buttonColor stroke-mainColor"
					}
				/>
			}
			grid="grid grid-cols-1"
			onClick={handleToggle}
			paddingX={paddingX}
			paddingY={paddingY}
			wishlist={true}
		/>
	);
};

export default WishlistButton;
