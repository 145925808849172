import React, { useEffect } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import image from "common/assets/images/about_us.webp";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";

const AboutPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<>
			<div className="grid minPhone:px-4 xl:px-32 minPhone:pt-28 xl:pt-32 minPhone:pb-6 xl:pb-20 minPhone:gap-6 xl:gap-8 min-phone:gap-4">
				<P size="p3" type="light">
					{t("another.aboutus")}
				</P>
				<div className="grid minPhone:gap-4 xl:gap-10 minPhone:grid-cols-1 xl:grid-cols-2">
					<div className="relative h-max minPhone:w-full xl:w-max">
						{/* <Logo className="minPhone:h-[200px] maxPhone:h-[350px] minPhone:w-full xl:h-[418px] xl:w-[522px] TwoXl:h-[468px] TwoXl:w-[610px] ThreeXl:w-full object-cover" /> */}
						<img
							src={image}
							alt="content"
							className="minPhone:h-[200px] maxPhone:h-[350px] minPhone:w-full xl:h-[418px] xl:w-[522px] TwoXl:h-[468px] TwoXl:w-[610px] ThreeXl:w-full object-cover"
						/>
						<div className="absolute top-0 bottom-0 right-0 left-0 grid place-content-center place-items-center bg-[rgba(0,0,0,0.3)]">
							<Htag tag="h1" type="regular" className="text-white">
								{t("another.aboutus")}
							</Htag>
						</div>
					</div>
					<div className="grid grid-flow-row gap-4 auto-rows-max">
						<P size="p1" type="light">
							{t("another.aboutTextOne")}
						</P>
						<P size="p1" type="light">
							{t("another.aboutTextTwo")}
						</P>
						{/* <P size="p1" type="light">
							{t("another.aboutTextThree")}
						</P>
						<P size="p1" type="light">
							{t("another.aboutTextFour")}
						</P>
						<P size="p1" type="light">
							{t("another.aboutTextFive")}
						</P> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutPage;
