// authAsyncThunks.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../services/authService";
import { setCookie } from "utils/cookie";

export interface LoginPayload {
	email: string;
	password: string;
}

export const auth = createAsyncThunk(
	"auth/login",
	async (payload: LoginPayload, thunkAPI) => {
		try {
			const response = await authService.login(payload.email, payload.password);
			const { token, tokenExpirationTime, user } = response;

			// Set token in cookie
			const expirationHours =
				new Date(tokenExpirationTime).getHours() - new Date().getHours();
			setCookie("token", token, expirationHours);
			setCookie("user", JSON.stringify(user), expirationHours);

			return { user, token, tokenExpirationTime };
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);
