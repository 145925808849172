import { P } from "common/P/P";
import React from "react";

export interface SelectItemProps {
	value: string;
	onSelect?: (value: string) => void;
	children: React.ReactNode;
	className?: string;
}

export const SelectItem: React.FC<SelectItemProps> = ({
	value,
	onSelect,
	children,
	className,
}) => {
	return (
		<div
			onClick={() => onSelect && onSelect(value)}
			className={`px-4 py-3 cursor-pointer hover:bg-grayOne ${
				className && className
			}`}
		>
			<P size="p3" type="light" className="cursor-pointer">
				{children}
			</P>
		</div>
	);
};
