import { createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "services/productService";

export const fetchSearchResults = createAsyncThunk(
	"search/fetchSearchResults",
	async (searchQuery: string, { rejectWithValue }) => {
		try {
			const response = await productService.searchProducts(searchQuery);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response?.data || "An error occurred");
		}
	}
);
