import { RootState } from "components/App/store";
import OrdersTable from "components/OrderTable/OrdersTable";
import Pagination from "components/Pagination/Pagination";
import { routeConstants } from "navigation";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderService } from "services/orderService";
import { ReactComponent as EmptyOrderIcon } from "common/assets/icons/no_orders.svg";
import { Htag } from "common/Htag/Htag";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import MobileOrders from "components/OrderTable/MobileOrders";

const OrdersListPage: React.FC = () => {
	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
	const itemsPerPage = 8;
	const [totalPages, setTotalPages] = useState(
		Math.ceil(orders.length / itemsPerPage)
	);
	const auth = useSelector((state: RootState) => state.auth.user);
	const userId = auth?._id || "";

	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleResize = () => {
		setIsMobileView(window.innerWidth < 1024);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const fetchAllOrders = async () => {
			setIsLoading(true);
			try {
				const response = await orderService.getOrdersByUser(userId);
				setOrders(response.data.orders);
				setTotalPages(Math.ceil(response.data.orders.length / itemsPerPage));
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
			setIsLoading(false);
		};

		fetchAllOrders();
	}, [userId]);

	const currentOrders = orders.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	const handleRowClick = (order: any) => {
		navigate(`/account/orders/${order._id}`);
	};
	return isLoading ? (
		<Spinner />
	) : (
		<div className="grid minPhone:mt-6 xl:mt-10 min-h-[50vh] gap-6">
			{currentOrders && currentOrders.length > 0 ? (
				<>
					{isMobileView ? (
						<MobileOrders orders={currentOrders} onRowClick={handleRowClick} />
					) : (
						<>
							<OrdersTable orders={currentOrders} onRowClick={handleRowClick} />
							<Pagination
								currentPage={currentPage}
								totalPages={totalPages}
								onPageChange={setCurrentPage}
							/>
						</>
					)}
				</>
			) : (
				<div className="grid grid-flow-row gap-4 place-items-center lg:py-8 auto-rows-max">
					<EmptyOrderIcon />
					<Htag tag="h3" type="regular">
						{t("another.noOrders")}
					</Htag>
				</div>
			)}
		</div>
	);
};

export default OrdersListPage;
