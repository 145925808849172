import { route_prefix } from "configs";

export const routeConstants = {
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	favourite: {
		route: `${route_prefix}/favourites`,
		name: "Favourites",
	},
	productDetails: {
		route: `${route_prefix}/product-details`,
		name: "Product Details",
	},
	checkout: {
		route: `${route_prefix}/checkout`,
		name: "Checkout",
	},
	filter: {
		route: `${route_prefix}/filter`,
		name: "Filter",
	},
	about: {
		route: `${route_prefix}/about-us`,
		name: "About us",
	},
	termsConditions: {
		route: `${route_prefix}/terms-conditions`,
		name: "Terms Conditions",
	},
	contact: {
		route: `${route_prefix}/contact-us`,
		name: "Contact us",
	},
	account: {
		route: `${route_prefix}/account`,
		name: "Account",
	},
	profile: {
		route: `${route_prefix}/profile`,
		name: "Profile",
	},
	orders: {
		route: `${route_prefix}/orders`,
		name: "Orders",
	},
	orderDetails: {
		route: `${route_prefix}/account/orders-details`,
		name: "Order Details",
	},
	login: {
		route: `${route_prefix}/login`,
		name: "Login",
	},
	register: {
		route: `${route_prefix}/register`,
		name: "Register",
	},
	forgetPassword: {
		route: `${route_prefix}/forget-password`,
		name: "Forget Password",
	},
	logout: {
		route: `${route_prefix}/logout`,
		name: "Logout",
	},
};
