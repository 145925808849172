import { createAsyncThunk } from "@reduxjs/toolkit";
import { sliderService } from "services/sliderService";

type Slider = {
	image: string;
};

export const fetchAllSliders = createAsyncThunk<{ data: Slider[] }, void, {}>(
	"slider/fetchAllSliders",
	async () => {
		return await sliderService.getAllSliders();
	}
);
