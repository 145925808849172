import { Htag } from "common/Htag/Htag";
import ProductCard from "components/ProductCard/ProductCard";
import React, { useEffect, useState } from "react";

import { P } from "common/P/P";
import { getWishlistForUser } from "services/wishlistService";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { ReactComponent as EmptyFavouriteIcon } from "common/assets/icons/no_favourites.svg";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const FavouritePage: React.FC = (): JSX.Element => {
	const [wishlist, setWishlist] = useState<any>([]); // State to store wishlist items
	const userId = useSelector((state: RootState) => state.auth.user?._id);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const fetchWishlist = async () => {
			try {
				setLoading(true);
				const fetchedWishlist = await getWishlistForUser(userId);
				console.log(fetchedWishlist);
				setWishlist(fetchedWishlist.data); // Assuming the response has a data field
			} catch (error) {
				console.error("Error fetching wishlist:", error);
				toast.error(t("messages.favouritesError"));
			} finally {
				setLoading(false); // Stop loading
			}
		};

		fetchWishlist();
	}, [userId]); // Dependency array to ensure it runs once

	const handleRemoveFromWishlist = (removedWishId: string) => {
		const updatedWishlist = wishlist.filter(
			(item: any) => item._id !== removedWishId
		);
		setWishlist(updatedWishlist); // Update the wishlist state
	};

	console.log("HEEHHEHE", wishlist);
	const renderProductCard = (
		product: any,
		onRemove: (removedWishId: any) => void
	) => {
		console.log(product, "asdas");
		let displayColor = product.productId.colors[0];

		const firstImage = displayColor.images[0] || ""; // Fallback image if not found
		const secondImage = displayColor.images[1] || ""; // Fallback image if not found
		const firstClothingSize = displayColor.clothingSizes[0]; // Assuming first size's details are used
		const firstShoesSize = displayColor.shoesSizes[0]; // Assuming first size's details are used

		// Assuming first size's price, discounted status, and discount percentage
		const price = firstClothingSize?.price || firstShoesSize?.price;
		const isDiscounted =
			firstClothingSize?.discounted || firstShoesSize?.discounted;
		const discountPercentage =
			firstClothingSize?.discount_percentage ||
			firstShoesSize?.discount_percentage;

		return (
			<ProductCard
				key={product.productId._id}
				imgSrc={[firstImage, secondImage]}
				name={getTranslation(product.productId.name)}
				price={price}
				discount={isDiscounted}
				discountPercentage={discountPercentage}
				id={product.productId._id}
				item={product.productId}
				wished={true}
				wishId={product._id}
				onRemoveFromWishlist={onRemove}
			/>
		);
	};

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<>
			{loading && <Spinner />}
			<div className="grid minPhone:px-4 xl:px-32 minPhone:pt-28 xl:pt-32 minPhone:pb-6 xl:pb-20 minPhone:gap-6 xl:gap-14 min-h-[95vh] grid-rows-maxFr">
				<P size="p3" type="light">
					{t("another.favourites")}
				</P>
				<div className="grid text-center minPhone:gap-4 xl:gap-10">
					<Htag tag="h1" type="regular">
						{t("another.myFavourite")}
					</Htag>

					{wishlist && wishlist.length > 0 ? (
						<div className="grid minPhone:gap-5 xl:gap-10 2xl:grid-cols-maxFour xl:grid-cols-maxThree minPhone:grid-cols-maxTwo minPhone:place-content-center xl:place-content-stretch">
							{wishlist.map((product: any) =>
								renderProductCard(product, handleRemoveFromWishlist)
							)}
						</div>
					) : (
						<div className="grid grid-flow-row gap-4 place-items-center lg:py-24 auto-rows-max">
							<EmptyFavouriteIcon />
							<Htag tag="h3" type="regular">
								{t("another.noFavourite")}
							</Htag>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default FavouritePage;
