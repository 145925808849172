import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import image from "common/assets/images/carousel.png";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { Button } from "common/Button/Button";
import { contactService } from "services/contactService";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const ContactPage: React.FC = (): JSX.Element => {
	const auth = useSelector((state: RootState) => state.auth.user);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const [username, setUsername] = useState(isLoggedIn ? auth?.username : "");
	const [email, setEmail] = useState(isLoggedIn ? auth?.email : "");
	const [message, setMessage] = useState<string>("");
	const [subject, setSubject] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const handleSubmit = async () => {
		setLoading(true);
		const contactData = {
			full_name: username,
			email: email,
			subject: subject,
			content: message,
		};

		try {
			const responseData = await contactService.submitContactData(contactData);
			toast.success(t("messages.contactSuccess"));
			setUsername("");
			setEmail("");
			setMessage("");
		} catch (error) {
			console.error("Failed to submit contact data:", error);
			toast.error(t("messages.contactSuccess"));
		} finally {
			setLoading(false); // Stop loading
		}
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;

		switch (name) {
			case "username":
				setUsername(value);
				break;
			case "email":
				setEmail(value);
				break;
			case "message":
				setMessage(value);
				break;
			case "subject":
				setSubject(value);
				break;
			default:
				break;
		}
	};

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<>
			{loading && <Spinner />}
			<div className="grid minPhone:px-4 xl:px-32 minPhone:pt-28 xl:pt-32 minPhone:pb-6 xl:pb-20 minPhone:gap-6 xl:gap-8 min-phone:gap-4">
				<P size="p3" type="light">
					{t("another.contactus")}
				</P>
				<div className="grid minPhone:gap-4 lg:gap-10 minPhone:grid-cols-1 lg:grid-cols-2">
					<div className="relative h-max lg:w-max minPhone:w-full">
						<img
							src={image}
							alt="content"
							className="minPhone:h-[200px] maxPhone:h-[350px] minPhone:w-full lg:h-[468px] lg:w-[572px] TwoXl:w-full object-cover"
						/>
						<div className="absolute top-0 bottom-0 right-0 left-0 grid place-content-center place-items-center bg-[rgba(0,0,0,0.3)]">
							<Htag tag="h1" type="regular" className="text-white">
								{t("another.contactus")}
							</Htag>
						</div>
					</div>
					<div className="grid grid-flow-row bg-white rounded-sm minPhone:gap-2 lg:gap-6 minPhone:p-0 lg:p-8 auto-rows-max">
						<Input
							appearance="default"
							type="text"
							name="username"
							minWidth={window.innerWidth < 1280 ? false : true}
							withlabel={true}
							label={t("another.username")}
							placeholder={t("another.username")}
							value={username}
							onChange={handleChange}
						/>

						<Input
							appearance="default"
							type="text"
							name="email"
							minWidth={window.innerWidth < 1280 ? false : true}
							withlabel={true}
							label={t("another.email")}
							placeholder={t("another.email")}
							value={email}
							onChange={handleChange}
						/>

						<Input
							appearance="default"
							type="text"
							name="subject"
							minWidth={window.innerWidth < 1280 ? false : true}
							withlabel={true}
							label={t("another.subject")}
							placeholder={t("another.subject")}
							value={subject}
							onChange={handleChange}
						/>

						<Textarea
							value={message}
							name="message"
							onChange={handleChange}
							placeholder={t("placeholders.yourMessage")}
							withlabel={true}
							label={t("another.textUs")}
							rows={5}
						/>
						<Button
							appearance="black"
							grid="grid grid-cols-1"
							width="full"
							onClick={handleSubmit}
						>
							{t("another.submit")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactPage;
