import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";

import { ReactComponent as SearchIcon } from "common/assets/icons/search.svg";
import { ReactComponent as HeartIcon } from "common/assets/icons/heart.svg";
import { ReactComponent as UserIcon } from "common/assets/icons/user.svg";
import { ReactComponent as CartIcon } from "common/assets/icons/bag.svg";
import { SearchInput } from "common/Search/Search";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import ManagePanel from "common/ManagePanel/ManagePanel";
import CartBody from "components/Cart/CartBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "components/App/store";
import { fetchAllCategories } from "redux/category/categoryAsyncThunk";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const Navbar: React.FC = (): JSX.Element => {
	const [showSearch, setShowSearch] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
	const [isScrolled, setIsScrolled] = useState(false);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const { i18n, t } = useTranslation();

	const navbarRef = useRef<HTMLDivElement | null>(null);
	const location = useLocation();
	const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();
	const categories = useSelector(
		(state: RootState) => state.category.categories
	);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
	const status = useSelector((state: RootState) => state.category.status);
	const cartItems = useSelector((state: RootState) => state.cart.items);

	useEffect(() => {
		if (status === "idle") {
			dispatch(fetchAllCategories());
		}
	}, [dispatch, status]);

	const handleScroll = () => {
		if (window.scrollY > 10) {
			setIsScrolled(true);
		} else {
			setIsScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleCategoryClick = (category: any) => {
		setShowSearch(false); // Close search dropdown if it's open

		// Check if the clicked category is already selected
		if (selectedCategory && category._id === selectedCategory._id) {
			// If it's already selected, deselect it (close the dropdown)
			setSelectedCategory(null);
			setActiveCategoryId(null);
		} else {
			// If it's not selected, select it (open the dropdown)
			setSelectedCategory(category);
			setActiveCategoryId(category._id);
		}
	};

	const handleSearchIconClick = () => {
		setSelectedCategory(null); // Close subcategories dropdown if it's open
		setActiveCategoryId(null); // Reset the active category ID
		setShowSearch(true);
	};

	const handleCartClick = () => {
		setIsCartOpen((prevState) => !prevState);
	};

	const handleCheckout = () => {
		navigate(routeConstants.checkout.route);
		setIsCartOpen(false);
	};

	// Determine the background color based on scroll position and dropdown status
	const getNavbarBg = () => {
		if (location.pathname === routeConstants.root.route) {
			if (isScrolled || showSearch || selectedCategory) {
				return "bg-white";
			}
			return "bg-transparent";
		} else {
			return "bg-white"; // or whatever default background you want for other routes
		}
	};

	const getTextColor = () => {
		if (location.pathname === routeConstants.root.route) {
			if (isScrolled || showSearch || selectedCategory) {
				return "text-mainColor";
			}
			return "text-white";
		} else {
			return "text-mainColor"; // or whatever default text color you want for other routes
		}
	};
	const isRootRoute = () => location.pathname === routeConstants.root.route;

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (navbarRef.current && !navbarRef.current.contains(event.target)) {
				setShowSearch(false);
				setActiveCategoryId(null);
				setSelectedCategory(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSubCategoryClick = (categoryId: any, categoryType?: any) => {
		navigate(
			`${routeConstants.filter.route}/category?categoryId=${categoryId}&categoryType=${categoryType}`
		);
		setSelectedCategory(null);
	};
	const changeLanguage = (lng: any) => {
		i18n.changeLanguage(lng).then(() => {});
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={`fixed top-0 left-0 w-full z-50`} ref={navbarRef}>
			<div
				className={`grid ${getNavbarBg()} ${
					!isRootRoute() || (isRootRoute() && isScrolled)
						? "border-b"
						: "border-none"
				} minPhone:py-14 minPhone:px-5 minPhone:grid-cols-1 minPhone:gap-14 lg:py-8 lg:px-10 xl:px-[104px] lg:grid-cols-maxFrMax TwoXl:grid-cols-3 lg:gap-8 border-dividerColor items-center`}
			>
				<Link to={routeConstants.root.route}>
					<Logo
						className={`lg:h-6 xl:h-8 ${
							!isRootRoute() || (isRootRoute() && isScrolled)
								? "fill-black"
								: showSearch || selectedCategory
								? "fill-black"
								: "fill-white"
						}`}
					/>
				</Link>
				<div className="grid lg:gap-8 xl:gap-[72px] grid-cols-maxThree place-content-center h-max">
					{categories &&
						categories.map((category: any) => (
							<Htag
								key={category._id}
								tag="h3"
								type="light"
								onClick={() => handleCategoryClick(category)}
								className={`cursor-pointer ${
									category._id === activeCategoryId
										? "border-b-2 border-mainColor"
										: ""
								} ${getTextColor()}`}
							>
								{getTranslation(category.name)}
							</Htag>
						))}
				</div>
				<div className="grid grid-flow-col-dense lg:gap-4 xl:gap-6 auto-cols-max place-items-center place-content-end h-max">
					<div className="grid grid-flow-col-dense gap-2 auto-cols-max place-items-center">
						<Htag
							tag="h3"
							type={i18n.language === "en" ? "regular" : "light"}
							className={`${getTextColor()} cursor-pointer`}
							onClick={() => changeLanguage("en")}
						>
							EN
						</Htag>

						<div
							className={`self-center w-px h-5 ${
								isRootRoute() && !isScrolled && !showSearch && !selectedCategory
									? "bg-white"
									: "bg-mainColor"
							}`}
						></div>
						<Htag
							tag="h3"
							type={i18n.language === "ru" ? "regular" : "light"}
							className={`${getTextColor()} cursor-pointer`}
							onClick={() => changeLanguage("ru")}
						>
							RU
						</Htag>
						<div
							className={`self-center w-px h-5 ${
								isRootRoute() && !isScrolled && !showSearch && !selectedCategory
									? "bg-white"
									: "bg-mainColor"
							}`}
						></div>

						<Htag
							tag="h3"
							type={i18n.language === "tk" ? "regular" : "light"}
							className={`${getTextColor()} cursor-pointer`}
							onClick={() => changeLanguage("tk")}
						>
							TM
						</Htag>
					</div>
					<div className="cursor-pointer" onClick={handleSearchIconClick}>
						<SearchIcon
							className={`${
								isRootRoute() && !isScrolled
									? !showSearch && !selectedCategory
										? "stroke-white"
										: "stroke-mainColor"
									: "stroke-mainColor"
							}`}
						/>
					</div>
					{isLoggedIn && (
						<Link to={routeConstants.favourite.route}>
							<HeartIcon
								className={`${
									isRootRoute() && !isScrolled
										? !showSearch && !selectedCategory
											? "stroke-white"
											: "stroke-mainColor"
										: "stroke-mainColor"
								}`}
							/>
						</Link>
					)}
					<Link to={routeConstants.account.route}>
						<UserIcon
							className={`${
								isRootRoute() && !isScrolled
									? !showSearch && !selectedCategory
										? "stroke-white"
										: "stroke-mainColor"
									: "stroke-mainColor"
							}`}
						/>
					</Link>
					<div className="grid gap-1 grid-cols-maxTwo place-items-center">
						<CartIcon
							onClick={handleCartClick}
							className={`${
								isRootRoute() && !isScrolled && !showSearch && !selectedCategory
									? "stroke-white"
									: "stroke-mainColor"
							} cursor-pointer`}
						/>
						<Htag tag="h3" type="light" className={`${getTextColor()}`}>
							({cartItems.length})
						</Htag>
					</div>
				</div>
			</div>
			{selectedCategory && (
				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -20 }}
					className={`absolute w-full bg-white border-t top-full lg:py-10 lg:px-10 xl:px-[104px] ${
						windowHeight < 900 ? "h-[70dvh]" : "h-max"
					} overflow-auto`}
				>
					<div className="grid gap-8 lg:grid-cols-maxSeven xl:grid-cols-maxFour ThreeXl:grid-cols-maxFive FourXl:grid-cols-maxSix ThreeXl:auto-cols-max TwoXl:gap-20 grid-cols-auto-fit">
						{selectedCategory.subCategories.map((subcat: any) => (
							<div
								key={subcat._id}
								className="grid grid-flow-row gap-6 auto-rows-max"
							>
								<P
									size="p1"
									type="regular"
									className="cursor-pointer"
									onClick={() => handleSubCategoryClick(subcat._id)}
								>
									{getTranslation(subcat.name)}
								</P>
								<div className="grid gap-2">
									{subcat.subSubCategories?.map((subsubcat: any) => (
										<P
											key={subsubcat._id}
											size="p2"
											type="light"
											className="cursor-pointer"
											onClick={() => handleSubCategoryClick(subsubcat._id)}
										>
											{getTranslation(subsubcat.name)}
										</P>
									))}
								</div>
							</div>
						))}
						<div className="grid grid-flow-row gap-6 auto-rows-max">
							<P
								size="p1"
								type="regular"
								className="cursor-pointer"
								onClick={() =>
									handleSubCategoryClick(selectedCategory._id, "newArrivals")
								}
							>
								{t("another.newArrives")}
							</P>
							<P
								size="p1"
								type="regular"
								className="cursor-pointer text-redColor"
								onClick={() =>
									handleSubCategoryClick(selectedCategory._id, "sales")
								}
							>
								{t("another.sales")}
							</P>
						</div>
					</div>
				</motion.div>
			)}
			{showSearch && (
				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -20 }}
					className="absolute w-full p-4 bg-white border-t top-full lg:py-4 lg:px-[128px] grid grid-cols-maxTwo items-center place-content-center gap-6"
				>
					<SearchInput
						placeholder={t("placeholders.search")}
						onChange={(e) => setSearchQuery(e.target.value)}
						onSearchClose={() => setShowSearch(false)}
					/>
					<P
						size="p1"
						type="light"
						className="cursor-pointer"
						onClick={() => setShowSearch(false)}
					>
						{t("another.cancel")}
					</P>
				</motion.div>
			)}
			<ManagePanel
				isOpen={isCartOpen}
				onClose={() => setIsCartOpen(false)}
				onCheckout={handleCheckout}
				label={t("another.myBag")}
				cart={true}
			>
				<CartBody />
			</ManagePanel>
		</div>
	);
};

export default Navbar;
