import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AccordionProps } from "./Accordion.props";

import { ReactComponent as MinusIcon } from "common/assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { ReactComponent as DotIcon } from "common/assets/icons/dot.svg";

import { P } from "common/P/P";
import splitTextBySeparator from "utils/splitTextBySymbol";

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
	const [isOpen, setIsOpen] = useState(false);

	const splitContent = splitTextBySeparator(content, "^");
	console.log("content ", splitContent);

	return (
		<div className="">
			<div
				className="grid gap-4 px-2 py-6 cursor-pointer grid-cols-frMax border-y border-dividerColor"
				onClick={() => setIsOpen(!isOpen)}
			>
				<P size="p2" type="regular" className="cursor-pointer">
					{title}
				</P>
				{isOpen ? (
					<MinusIcon className="w-6 h-6" />
				) : (
					<PlusIcon className="w-6 h-6" />
				)}
			</div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: "auto" }}
						exit={{ opacity: 0, height: 0 }}
						className="grid gap-1 overflow-hidden"
					>
						<div className="grid grid-cols-1 gap-1 px-2 first:mt-6 last:mb-6">
							{splitContent.map((item: any, idx: number) => (
								<div key={idx} className="flex items-center gap-3">
									<DotIcon className="w-1 h-1" />
									<P size="p2" type="light">
										{item}
									</P>
								</div>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default Accordion;
