import axios from "axios";
import { serviceConfig } from "configs";

const getAllCategories = async () => {
	try {
		const response = await axios.get(`${serviceConfig.apiUrl}/categories`);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error("An unexpected error occurred.");
	}
};

const getCategoryProducts = async (categoryId: any) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/category/products/${categoryId}`
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error(
			"An unexpected error occurred while fetching category products."
		);
	}
};

const getCategoryNewArrivals = async (categoryId: any) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/category/${categoryId}/new-arrivals`
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error(
			"An unexpected error occurred while fetching new arrivals."
		);
	}
};

const getCategorySales = async (categoryId: any) => {
	try {
		const response = await axios.get(
			`${serviceConfig.apiUrl}/category/${categoryId}/sales`
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw error.response?.data;
		}
		throw new Error(
			"An unexpected error occurred while fetching sales products."
		);
	}
};
export const categoryService = {
	getAllCategories,
	getCategoryProducts,
	getCategoryNewArrivals,
	getCategorySales,
};
