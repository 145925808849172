import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { ReactComponent as ErrorIcon } from "common/assets/icons/warning-circle.svg";
import loginImage from "common/assets/images/login_image.png";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { useDispatch } from "react-redux";
import { authService } from "services/authService";
import OTPModal from "components/OTPModal/OTPModal";

import { AppDispatch } from "components/App/store";
import { auth } from "redux/auth/authAsyncThunks";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";

const RegisterPage: React.FC = (): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		username: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [registerError, setRegisterError] = useState<string | null>(null);
	const [showOTPModal, setShowOTPModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const validateEmail = (email: string): boolean => {
		const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return pattern.test(email);
	};

	const handleRegister = async () => {
		setIsLoading(true);
		setRegisterError(null);
		if (!validateEmail(formData.email)) {
			toast.error(t("another.invalidEmail"));
			setIsLoading(false);
			return;
		}

		if (formData.password.length < 6) {
			toast.error(t("another.passwordLength"));
			setIsLoading(false);
			return;
		}

		if (formData.password !== formData.confirmPassword) {
			toast.error(t("another.passwordMismatch"));
			setIsLoading(false);
			return;
		}

		try {
			await authService.registerInit(
				formData.username,
				formData.email,
				formData.password
			);
			setShowOTPModal(true);
			toast.success(t("messages.registerSuccess"));
		} catch (error) {
			setRegisterError("Failed to register.");
			toast.error(t("messages.registerError"));
		} finally {
			setIsLoading(false);
		}
	};
	const handleConfirmOTP = async (otp: string) => {
		try {
			const response = await authService.registerFinal(formData.email, otp);

			dispatch(auth({ email: formData.email, password: formData.password }));

			setShowOTPModal(false);
			toast.success(t("messages.otpSuccess"));
			navigate(routeConstants.root.route);
		} catch (error) {
			setRegisterError("Failed to confirm OTP.");
			toast.error(t("messages.otpError"));
		}
	};

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<div className="grid minPhone:grid-cols-1 xl:grid-cols-2">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-10 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0">
				<Htag tag="h1" type="regular">
					{t("another.register")}
				</Htag>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="username"
						withlabel={true}
						label={t("another.username")}
						placeholder={t("another.username")}
						onChange={handleInputChange}
						value={formData.username}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="email"
						name="email"
						withlabel={true}
						label={t("another.email")}
						placeholder={t("another.email")}
						onChange={handleInputChange}
						value={formData.email}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						name="password"
						withlabel={true}
						label={t("another.password")}
						placeholder={t("another.password")}
						onChange={handleInputChange}
						value={formData.password}
					/>
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="password"
						name="confirmPassword"
						withlabel={true}
						label={t("another.confirmPassword")}
						placeholder={t("another.confirmPassword")}
						onChange={handleInputChange}
						value={formData.confirmPassword}
					/>
					<div className="grid gap-5">
						{isLoading ? (
							<Spinner inModal={true} /> // Render Spinner here
						) : (
							<Button
								appearance={
									formData.email &&
									formData.username &&
									formData.password &&
									formData.confirmPassword
										? "white"
										: "disabled"
								}
								width="full"
								onClick={handleRegister}
							>
								{t("another.register")}
							</Button>
						)}
						<div className="grid gap-2 grid-cols-maxTwo place-items-center place-content-center">
							<P size="p2" type="light" className="text-center">
								{t("another.haveAccount")}
							</P>
							<Link to={routeConstants.login.route}>
								<P
									size="p2"
									type="light"
									className="text-center cursor-pointer"
								>
									{t("another.signIn")}
								</P>
							</Link>
						</div>
					</div>
					{/* {registerError && (
						<div className="grid gap-2 grid-cols-maxTwo place-items-center">
							<ErrorIcon className="stroke-redColor" />
							<P size="p3" type="light" className="text-redColor">
								{registerError}
							</P>
						</div>
					)} */}
					<OTPModal
						onClose={() => setShowOTPModal(false)}
						isOpen={showOTPModal}
						onConfirm={handleConfirmOTP}
					/>
				</div>
			</div>
			<img
				src={loginImage}
				alt="login_image"
				className="object-cover w-full h-full minPhone:hidden xl:block"
			/>
		</div>
	);
};

export default RegisterPage;
