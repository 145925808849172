import React from "react";
import { StyleManagerItemProps } from "./StyleManagerItem.props";
import { P } from "common/P/P";

const StyleManagerItem: React.FC<StyleManagerItemProps> = ({
	label,
	paddingY = "py-2",
	onClick,
	className,
	disabled,
	selected = false,
}) => {
	return (
		<div
			className={`border rounded-sm ${
				disabled
					? "border-grayTwo bg-buttonColor cursor-not-allowed"
					: selected
					? "border-mainColor bg-mainColor cursor-pointer" // Style for selected state
					: "border-mainColor bg-white cursor-pointer"
			} ${paddingY} px-4 ${className}`}
			onClick={onClick}
		>
			<P
				size="p3"
				type="light"
				className={`${
					disabled
						? "text-grayTwo cursor-not-allowed"
						: selected
						? "text-white"
						: "text-mainColor cursor-pointer"
				}`}
			>
				{label}
			</P>
		</div>
	);
};

export default StyleManagerItem;
