import React, { useState } from "react";
import { InputProps } from "./Input.props";
import { P } from "common/P/P";
import "./Input.css";
import { ReactComponent as CalendarIcon } from "common/assets/icons/calendar.svg";
import { ReactComponent as BlockIcon } from "common/assets/icons/lock.svg";
import { ReactComponent as EyeOffIcon } from "common/assets/icons/eye-close.svg";
import { ReactComponent as EyeIcon } from "common/assets/icons/eye-alt.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash.svg";
import { serviceConfig } from "configs";

export const Input = ({
	appearance = "default",
	label,
	className,
	type,
	value,
	name,
	placeholder,
	editButton,
	onChange,
	children,
	maxLength,
	minLength,
	calendar,
	price,
	discount,
	withlabel,
	discountedPrice,
	width = "w-full",
	height = "h-full",
	minWidth = true,
	textCenter = false,
	hint,
	deletable,
	onDelete,
	...props
}: InputProps): JSX.Element => {
	const currentDate = new Date().toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handlePasswordToggle = () => {
		setPasswordVisible((prev) => !prev);
	};

	return (
		<>
			{withlabel ? (
				<div className={`grid gap-1 ${className}`}>
					<label>
						<P size="p3" type="light" className="text-textColor">
							{label}
						</P>
					</label>
					{editButton ? (
						<div
							className={
								!children ? "grid grid-cols-3 gap-4" : "grid grid-cols-2 gap-4"
							}
						>
							<input
								type={type}
								value={value}
								name={name}
								onChange={onChange}
								{...props}
								className={`input w-full h-full uppercase text-sm font-light px-4 py-4 border border-mainColor rounded-sm transition-all duration-300 ${
									minWidth && "min-w-[470px]"
								} ${
									appearance === "error" &&
									"error text-redColor border-redColor hover:border-redColor focus:border-redColor focus-visible:border-redColor"
								}`}
								placeholder={placeholder}
								maxLength={maxLength}
								minLength={minLength}
							/>
							{children}
						</div>
					) : (
						<div className="relative">
							<input
								type={
									type === "password"
										? passwordVisible
											? "text"
											: "password"
										: type
								}
								value={value}
								name={name}
								onChange={onChange}
								{...props}
								className={`input w-full h-full uppercase text-sm font-light px-4 py-4 border border-mainColor rounded-sm transition-all duration-300 ${
									minWidth && "min-w-[470px]"
								} ${
									appearance === "error" &&
									"error text-redColor border-redColor hover:border-redColor focus:border-redColor focus-visible:border-redColor"
								}`}
								placeholder={placeholder}
								maxLength={maxLength}
								minLength={minLength}
							/>
							{type === "password" && (
								<span
									className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
									onClick={handlePasswordToggle}
								>
									{passwordVisible ? <EyeIcon /> : <EyeOffIcon />}
								</span>
							)}
							{deletable && (
								<span
									className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
									onClick={onDelete}
								>
									<DeleteIcon />
								</span>
							)}
						</div>
					)}
					{appearance === "withHint" && (
						<span className="inline-block mt-1.5 text-xs font-normal text-passiveTextColor leading-5">
							This is a hint text to help user.
						</span>
					)}
					{appearance === "error" && (
						<span className="inline-block mt-1.5 text-xs font-normal text-redColor leading-5">
							Password is a required field.
						</span>
					)}
				</div>
			) : calendar ? (
				<div className="relative">
					<span className="absolute inset-y-0 left-0 flex items-center px-4">
						<CalendarIcon className="stroke-passiveTextColor" />
					</span>

					<span className="absolute inset-y-0 flex items-center cursor-default left-12 text-passiveTextColor">
						{currentDate}
					</span>

					<input
						type={type}
						value={value}
						name={name}
						onChange={onChange}
						{...props}
						className={`w-full h-full py-2 border border-mainColor rounded-sm`}
						placeholder={placeholder}
						maxLength={maxLength}
						minLength={minLength}
						disabled
					/>
				</div>
			) : price ? (
				<div className={`${label && "grid gap-1"}`}>
					{label && (
						<label>
							<P size="p3" type="light" className="text-passiveTextColor">
								{label}
							</P>
						</label>
					)}

					<div className="relative">
						<span className="absolute inset-y-0 left-0 flex items-center px-4 py-2 bg-white border rounded-l-lg cursor-default text-passiveTextColor border-mainColor">
							{serviceConfig.currencySign}
						</span>
						<input
							type={type}
							value={value}
							name={name}
							onChange={onChange}
							{...props}
							className={`inputDefault h-full py-2 border border-mainColor rounded-sm ${
								className ? className : "w-full px-20"
							}`}
							placeholder={placeholder}
							maxLength={maxLength}
							minLength={minLength}
						/>
					</div>
				</div>
			) : discount ? (
				<div className={`${label && "grid gap-1"} ${className}`}>
					{label && (
						<label>
							<P size="p3" type="light" className="text-passiveTextColor">
								{label}
							</P>
						</label>
					)}
					<div className="relative flex">
						{/* Writable Input */}
						<input
							type={type}
							value={value}
							name={name}
							onChange={onChange}
							{...props}
							className={`inputDefault w-20 h-full py-2 px-4 border border-mainColor rounded-sm rounded-r-none text-center`}
							placeholder={placeholder}
							maxLength={4}
							minLength={minLength}
						/>

						{/* Disabled Input with Icon */}
						<div className="flex h-auto gap-4 px-4 py-1 border rounded-sm rounded-l-none w-max place-content-center place-items-center bg-hoverColor border-mainColor">
							{discountedPrice && (
								<label>
									<P size="p2" type="light" className="text-passiveTextColor">
										{discountedPrice}
									</P>
								</label>
							)}
							<BlockIcon className="stroke-passiveTextColor" />
						</div>
					</div>
					{hint && (
						<span className="inline-block mt-1.5 text-xs font-normal text-passiveTextColor leading-5">
							{hint}
						</span>
					)}
				</div>
			) : (
				<div className="relative">
					<input
						type={type}
						value={value}
						name={name}
						onChange={onChange}
						{...props}
						className={`input ${width} ${height} uppercase text-sm font-light px-4 py-4 border border-mainColor rounded-sm transition-all duration-300 ${
							minWidth && "min-w-[470px]"
						} ${textCenter && "text-center"} ${
							appearance === "error" &&
							"error text-redColor border-redColor hover:border-redColor focus:border-redColor focus-visible:border-redColor"
						}`}
						placeholder={placeholder}
						maxLength={maxLength}
						minLength={minLength}
					/>
					{deletable && (
						<span
							className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
							onClick={onDelete}
						>
							<DeleteIcon />
						</span>
					)}
					{appearance === "withHint" && (
						<span className="inline-block mt-1.5 text-xs font-normal text-passiveTextColor leading-5">
							This is a hint text to help user.
						</span>
					)}
					{appearance === "error" && (
						<span className="inline-block mt-1.5 text-xs font-normal text-redColor leading-5">
							Password is a required field.
						</span>
					)}
				</div>
			)}
		</>
	);
};
