import React from "react";
import Slider from "react-slick";
import { ReactComponent as LeftArrow } from "common/assets/icons/nav-arrow-left.svg";
import { ReactComponent as RightArrow } from "common/assets/icons/nav-arrow-right.svg";

type SliderProps = {
	images: string[];
};

const SliderComponent: React.FC<SliderProps> = ({ images }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	return (
		<div className="relative">
			<Slider {...settings}>
				{images.map((image, index) => (
					<div key={index}>
						<img
							src={image}
							alt={`Slide ${index + 1}`}
							className="w-full minPhone:h-[664px] xl:h-[864px] object-cover"
						/>
					</div>
				))}
			</Slider>
		</div>
	);
};

const NextArrow: React.FC<any> = ({ onClick }) => {
	return (
		<RightArrow
			className="absolute z-10 transform -translate-y-1/2 cursor-pointer minPhone:right-2 xl:right-10 slider-arrow next top-1/2 xl:w-14 xl:h-14 minPhone:w-9 minPhone:h-9 stroke-white"
			onClick={onClick}
		/>
	);
};

const PrevArrow: React.FC<any> = ({ onClick }) => {
	return (
		<LeftArrow
			className="absolute z-10 transform -translate-y-1/2 cursor-pointer xl:left-10 minPhone:left-2 slider-arrow prev top-1/2 xl:w-14 xl:h-14 minPhone:w-9 minPhone:h-9 stroke-white"
			onClick={onClick}
		/>
	);
};

export default SliderComponent;
