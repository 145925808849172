import { Outlet } from "react-router-dom";

const OrdersPage: React.FC = () => {
	return (
		<div className="grid minPhone:mt-6 xl:mt-10 min-h-[50vh] gap-6">
			<Outlet />
		</div>
	);
};

export default OrdersPage;
