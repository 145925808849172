// components/CartBody.tsx
import React from "react";
import CartRow from "./CartRow";

import { useSelector } from "react-redux";
import { RootState } from "components/App/store";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { ReactComponent as EmptyBagIcon } from "common/assets/icons/no_bag.svg";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";
import { serviceConfig } from "configs";

const CartBody = ({
	checkoutPage,
	orderPage,
	orderProducts = null,
	className,
}: any) => {
	const { t } = useTranslation();
	const cartItems = useSelector((state: RootState) => state.cart.items);
	const totalAmount = useSelector((state: RootState) => state.cart.totalAmount);

	const itemsToDisplay = orderProducts || cartItems;
	console.log("LOL", itemsToDisplay);

	return (
		<div
			className={`grid grid-cols-1 h-full ${
				!checkoutPage && !orderPage && "bg-white"
			} ${
				cartItems.length > 0
					? "minPhone:place-content-start minPhone:gap-10 lg:gap-0 lg:place-content-between"
					: "place-content-center"
			} ${className}`}
		>
			<div className="grid gap-6">
				{itemsToDisplay.length > 0 ? (
					itemsToDisplay.map((item: any) => {
						const product = orderProducts ? item : item.product;
						console.log("PRODUCT", product);
						console.log("ITEM SELECTED", item.selectedSizeId);
						let colorInfo;
						if (orderProducts) {
							colorInfo = product.product.colors.find(
								(color: any) => color._id === item.selectedColorId
							);
						} else {
							colorInfo = product.colors.find(
								(color: any) => color._id === item.selectedColorId._id
							);
						}
						console.log("COLOR INFO", colorInfo);

						if (!colorInfo) {
							return <div key={product._id}>Color not found</div>;
						}

						let sizeInfo;

						if (orderProducts) {
							sizeInfo = [
								...(colorInfo.clothingSizes || []),
								...(colorInfo.shoesSizes || []),
							].find((size) => size.sizeId._id === item.selectedSizeId);
						} else {
							sizeInfo = [
								...(colorInfo.clothingSizes || []),
								...(colorInfo.shoesSizes || []),
							].find(
								(size) => size.sizeId._id === item.selectedSizeId.sizeId._id
							);
						}

						if (!sizeInfo) {
							return <div key={product._id}>Size not found</div>;
						}

						const isDiscounted = sizeInfo.discounted;
						const discountPercentage = sizeInfo.discount_percentage;
						const discountPrice = sizeInfo.discount_price;

						console.log(
							"TRANSLATEEEE",
							getTranslation(colorInfo?.colorId?.name)
						);

						return (
							<CartRow
								key={product._id}
								imgSrc={colorInfo.images[0]}
								name={
									orderProducts
										? getTranslation(product.product.name)
										: getTranslation(product.name)
								}
								size={sizeInfo.sizeId.size}
								color={
									getTranslation(colorInfo?.name) ||
									getTranslation(colorInfo?.colorId?.name)
								}
								price={sizeInfo.price}
								quantity={item.quantity}
								productId={product._id}
								product={product}
								discount={isDiscounted}
								discountPercentage={discountPercentage}
								discountPrice={discountPrice}
								checkoutPage={checkoutPage}
								orderPage={orderPage}
								selectedColorId={item.selectedColorId._id}
								selectedSizeId={item.selectedSizeId?._id}
							/>
						);
					})
				) : (
					<div className="grid grid-flow-row gap-4 place-items-center lg:py-8 auto-rows-max">
						<EmptyBagIcon />
						<Htag tag="h3" type="regular">
							{t("another.emptyBag")}
						</Htag>
					</div>
				)}
			</div>
			{!checkoutPage && !orderPage && itemsToDisplay.length > 0 && (
				<div className="w-full px-2 pb-4">
					<div className="grid grid-cols-maxTwo place-content-between place-items-center">
						<P size="p2" type="regular">
							{t("another.total")}:
						</P>
						<P size="p2" type="regular">
							{totalAmount.toFixed(2)} {serviceConfig.currencySign}
						</P>
					</div>
				</div>
			)}
		</div>
	);
};

export default CartBody;
