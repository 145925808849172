import { createSlice } from "@reduxjs/toolkit";
import { fetchAllCategories } from "./categoryAsyncThunk";

type CategoryState = {
	categories: any[];
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
};

const initialState: CategoryState = {
	categories: [],
	status: "idle",
	error: null,
};

const categorySlice = createSlice({
	name: "categories",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllCategories.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchAllCategories.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.categories = action.payload;
			})
			.addCase(fetchAllCategories.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message || null;
			});
	},
});

export default categorySlice.reducer;
