import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import loginImage from "common/assets/images/login_image.png";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { ReactComponent as ErrorIcon } from "common/assets/icons/warning-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "redux/auth/authAsyncThunks";
import { AppDispatch, RootState } from "components/App/store";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";

const LoginPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	const [formData, setFormData] = useState({ email: "", password: "" });
	const [formError, setFormError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === "password") {
			setFormError(null);
		}
		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleLogin = async () => {
		if (formData.password.length < 6) {
			toast.error(t("another.passwordLength"));
			return;
		}

		setIsLoading(true);
		try {
			await dispatch(auth(formData)).unwrap(); // Use unwrap to handle the promise
			toast.success(t("messages.loginSucces"));
		} catch (error) {
			toast.error(t("messages.loginError"));
			setFormError("Invalid credentials or login failed.");
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		if (isLoggedIn) {
			navigate(routeConstants.root.route);
		}
	}, [isLoggedIn, navigate]);

	const smoothScrollToTop = () => {
		const currentPosition = window.pageYOffset;
		if (currentPosition > 0) {
			window.requestAnimationFrame(smoothScrollToTop);
			window.scrollTo(0, currentPosition - currentPosition / 8);
		}
	};

	useEffect(() => {
		smoothScrollToTop();
	}, []);

	return (
		<div className="grid minPhone:grid-cols-1 xl:grid-cols-2">
			<div className="grid grid-flow-row minPhone:gap-6 xl:gap-10 xl:place-content-center minPhone:place-items-stretch xl:place-items-center auto-rows-max minPhone:pt-28 minPhone:pb-8 xl:pt-0 xl:pb-0 minPhone:px-4 xl:px-0">
				<Htag tag="h1" type="regular">
					{t("another.login")}
				</Htag>
				<div className="grid gap-6">
					<Input
						appearance="default"
						minWidth={window.innerWidth < 1280 ? false : true}
						type="text"
						name="email"
						withlabel={true}
						label={t("another.email")}
						placeholder={t("another.email")}
						onChange={handleInputChange}
						value={formData.email}
					/>
					<div className="grid gap-4">
						<Input
							appearance="default"
							minWidth={window.innerWidth < 1280 ? false : true}
							type="password"
							name="password"
							withlabel={true}
							label={t("another.password")}
							placeholder={t("placeholders.password")}
							onChange={handleInputChange}
							value={formData.password}
						/>
						<Link to={routeConstants.forgetPassword.route}>
							<P size="p3" type="light" className="cursor-pointer text-end">
								{t("another.forgetPassword")}
							</P>
						</Link>
					</div>
					<div className="grid gap-5">
						{isLoading ? (
							<Spinner inModal={true} />
						) : (
							<Button
								appearance="white"
								width="full"
								disabled={formData.password.length < 6}
								className={
									formData.password.length < 6 ? "cursor-not-allowed" : ""
								}
								onClick={handleLogin}
							>
								{t("another.signIn")}
							</Button>
						)}
						<Link to={routeConstants.register.route}>
							<P size="p2" type="light" className="text-center cursor-pointer">
								{t("another.createAccount")}
							</P>
						</Link>
					</div>
					{/* {formError && (
						<div className="grid gap-2 grid-cols-maxTwo place-items-center">
							<ErrorIcon className="stroke-redColor" />
							<P size="p3" type="light" className="text-redColor">
								{formError}
							</P>
						</div>
					)} */}
				</div>
			</div>

			<img
				src={loginImage}
				alt="login_image"
				className="object-cover w-full h-full minPhone:hidden xl:block"
			/>
		</div>
	);
};

export default LoginPage;
