import React from "react";
import { RadioButtonProps } from "./RadioButton.props";
import { P } from "common/P/P";

const RadioButton: React.FC<RadioButtonProps> = ({
	id,
	name,
	value,
	label,
	checked = false,
	onChange,
	className,
}) => {
	return (
		<label
			htmlFor={id}
			className={`flex items-center cursor-pointer ${className}`}
		>
			<input
				type="radio"
				id={id}
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
				className="hidden"
			/>
			<span
				className={`relative w-5 h-5 border rounded-full mr-2 border-mainColor ${
					checked ? "bg-white" : ""
				}`}
			>
				<span
					className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[10px] h-[10px] rounded-full ${
						checked ? "bg-mainColor" : "opacity-0"
					}`}
				></span>
			</span>
			<P size="p2" type="light" className="cursor-pointer">
				{label}
			</P>
		</label>
	);
};

export default RadioButton;
