import React, { useState } from "react";
import { ProductProps } from "./ProductCard.props";
import { P } from "common/P/P";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import WishlistButton from "common/WishlistButton/WishlistButton";
import { Link } from "react-router-dom";
import { routeConstants } from "navigation";
import { serviceConfig } from "configs";
import { addItem, removeItem } from "redux/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "components/App/store";
import { addToWishlist, removeFromWishlist } from "services/wishlistService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ProductCard: React.FC<ProductProps> = ({
	imgSrc,
	name,
	price,
	discount,
	discountPercentage,
	id,
	item,
	wished,
	wishId,
	onRemoveFromWishlist,
}): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const userId = useSelector((state: RootState) => state.auth.user?._id);
	const [currentImage, setCurrentImage] = useState(
		`${serviceConfig.apiUrl}${imgSrc[0]}`
	);
	const { t } = useTranslation();
	console.log("Product", discountPercentage);

	const discountedPrice = discount
		? price - (price * discountPercentage) / 100
		: price;

	const isInWishlist = (product: any) => {
		return wished;
	};

	// Placeholder function: Handle toggling the wishlist status of a product
	// const handleToggleWishlist = async () => {
	// 	try {
	// 		if (wished) {
	// 			// await removeFromWishlist(wishId); // Remove from wishlist
	// 			console.log(`Removed ${name} from wishlist`);
	// 			onRemoveFromWishlist(wishId); // Update UI
	// 		} else {
	// 		}
	// 	} catch (error) {
	// 		console.error("Wishlist update error:", error);
	// 		// Optionally handle errors (like showing a message to the user)
	// 	}
	// };

	const cartItems = useSelector((state: RootState) => state.cart.items);
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	const isInCart = (product: any) =>
		cartItems.some((cartItem) => cartItem.product._id === product._id);

	const handleAddOrRemoveFromCart = () => {
		if (item && item.colors && item.colors.length > 0) {
			const defaultColor = item.colors[0];
			const defaultSize =
				defaultColor.clothingSizes?.find((size: any) => size.stock > 0) ||
				defaultColor.shoesSizes?.find((size: any) => size.stock > 0);

			if (!defaultSize) {
				toast.error(t("messages.sizeRequiredError"));
				return;
			}

			// Merge defaultColor object with colorId properties
			const selectedColor = {
				...defaultColor.colorId,
				...defaultColor.name,
				...defaultColor,
				colorId: undefined, // Remove the nested colorId property
			};

			if (isInCart(item)) {
				dispatch(
					removeItem({
						productId: item._id,
						selectedColorId: selectedColor._id, // Use the merged selectedColor object
						selectedSizeId: defaultSize._id,
					})
				);
			} else {
				const cartItem = {
					product: item,
					selectedColorId: selectedColor, // Use the merged selectedColor object
					selectedSizeId: defaultSize,
					quantity: 1,
					price: discountedPrice, // Ensure discountedPrice is correctly calculated
				};
				dispatch(addItem(cartItem));
			}
		} else {
			console.error("No colors found for the product.");
		}
	};

	return (
		<div className="grid gap-6 overflow-hidden w-max minPhone:max-w-[166px] maxPhone:max-w-[230px] md:max-w-[230px] lg:max-w-[300px] xl:max-w-[368px]">
			<div className="relative">
				<Link to={`${routeConstants.productDetails.route}/${id}`}>
					<img
						src={currentImage}
						alt={name}
						className="object-cover minPhone:w-[166px] maxPhone:w-full md:w-[230px] minPhone:h-[346px] lg:w-[300px] xl:w-[368px] lg:h-[488px]"
						onMouseEnter={() =>
							setCurrentImage(`${serviceConfig.apiUrl}${imgSrc[1]}`)
						}
						onMouseLeave={() =>
							setCurrentImage(`${serviceConfig.apiUrl}${imgSrc[0]}`)
						}
					/>
				</Link>
				{discount && (
					<span className="absolute px-2 py-1 rounded-sm bg-mainColor bottom-2 left-2">
						<P size="p2" type="light" className="text-white">
							{discountPercentage}%
						</P>
					</span>
				)}
			</div>
			<div className="grid gap-4 text-center">
				<div className="grid grid-rows-maxTwo">
					<P
						size="p2"
						type="regular"
						className="truncate minPhone:text-sm xl:text-base"
					>
						{name}
					</P>
					<div className="flex items-center place-content-center">
						{discount && discountPercentage > 0 ? (
							<span className="grid gap-2 grid-cols-maxTwo place-items-center">
								<P
									size="p2"
									type="light"
									className="line-through minPhone:text-sm xl:text-base"
								>
									{price} {serviceConfig.currencySign}
								</P>
								<Htag
									tag="h2"
									type="light"
									className="minPhone:text-base xl:text-2xl"
								>
									{discountedPrice} {serviceConfig.currencySign}
								</Htag>
							</span>
						) : (
							<Htag
								tag="h2"
								type="light"
								className="minPhone:text-base xl:text-2xl"
							>
								{price} {serviceConfig.currencySign}
							</Htag>
						)}
					</div>
				</div>
				<div
					className={`grid ${
						isLoggedIn
							? "minPhone:gap-2 xl:gap-4 grid-cols-frMax"
							: "grid-cols-1"
					}  place-items-center`}
				>
					<Button
						appearance="white"
						grid="grid grid-cols-1"
						width="full"
						paddingX="minPhone:px-4 xl:px-16"
						paddingY="minPhone:py-[0.65rem] xl:py-4"
						className="minPhone:text-sm xl:text-base"
						onClick={handleAddOrRemoveFromCart}
					>
						{isInCart(item) ? t("another.remove") : t("another.buyNow")}
					</Button>
					{isLoggedIn && (
						<WishlistButton
							isActive={wished}
							wished={wished}
							// onToggle={handleRemoveFromWishlist}
							productId={item._id}
							wishId={wishId}
							product={item}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
